import React from 'react';
import './normal.css';
import Subscribe from '../../components/calltoaction/Subscribe';
import { Link } from 'react-router-dom';

const SecurePage = () => {
  return (
    <>
      <div className="new_sec blog_100_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 mb-2">
              <p>Category {'>'} Projects</p>
              <h1 className="title_text_blog mb-2 text-custom">
                Creating A Secure South Sudan
              </h1>
              <p>
                Gender based violence is prevalent across all communities in
                South Sudan. It is a threat to human and national security;
                therefore, it continues to be a threat to sustainable peace and
                development. As women and girls are the main victims and most
                ignored by traditional security, it is important to hear their
                perspectives about the security challenges they face.
              </p>
              <p>
                27, April, 2023 &nbsp; <i class="bx bx-book-reader"></i> 6 mins
                read
              </p>
              <div className="author_div">
                <img src="./image/team/7.png" alt="" />
                <div className="mt-2">
                  <h4 className="fn_14 ">Nyoka</h4>
                  <p className="fn_10 mig">Finance | Leadership Advocate</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 mb-2">
              <img
                src="./image/2ian-macharia-509884-unsplash.png"
                alt=""
                className="blog_post_immmg"
              />
            </div>
          </div>
          <div className="row mt-4">
            <h5>
              A community dialogue was organized by the South Sudan Women
              Coalition across the ten state capitals and Lokwilili. The
              participants were women from the communities, civil society
              organizations, religious societies etc. The dialogue provided a
              platform for the women to interact and articulate the security
              issues affecting them.
            </h5>
            <p className="mt-4">
              The overall objective of the Coalition is to build a strong
              Women’s Movement in South Sudan, which will be able to address a
              wide range of issues under women, peace and security undermining
              their effective engagement on matters affecting their lives,
              including insecurity, underrepresentation in leadership and
              decision-making structures, national development and peace
              building. The coalition was co-created by three institutions
              namely, EVE Organization for Women Development, The National
              Transformational Leadership Institute and the South Sudan Women
              General Association (SSWGA). The Women Association is an umbrella
              organization with presence in all states- from national to
              grassroots levels, while EVE has presence in South Sudan and other
              East African countries – Kenya and Uganda, making possible to
              reach out women in the refugees’ camps and urban areas who were
              displaced by conflict in the country. Since inception, the three
              institutions have worked hard to facilitate communication and
              collection of women’s common agenda for peace and participation in
              the on-going peace building processes, which is major goal by
              which the coalition is premised. Since September 2017, the
              Coalition has been actively engaged in building trust among women
              through awareness raising and information and experience sharing
              develop common positions. Organizing joint activities such as
              peace march and prayers. These activities have continued to
              strengthen their demands and pushed for their inclusion and
              meaningful participation in the High- Level Revitalization Forum
              (HRLF).
            </p>
            <h3 className="mt-4 mb-4">
              Security challenges within the community
            </h3>
            <p className="mt-3 mb-2">
              Gender based violence in its various forms was the main security
              challenge identified across all the areas. Domestic violence is
              the most common form of GBV. In many communities, the close-knit
              family structure is valued whereas divorce or broken families are
              disapproved of. Domestic violence is largely normalized and some
              even regard it as a man's right to discipline his wife. Many women
              choose to keep the domestic violence to themselves for reasons
              such as not wanting to break the family. "For us business women,
              our husbands take our money by force to go and drink and sleep
              with other women.{' '}
            </p>{' '}
            <p>
              {' '}
              Then they come back and beat us when we are sleeping hungry with
              the children. We cannot report because when we do, the marriage
              will be over. So we are working for someone and taking care of
              their children at the same time" As domestic violence is
              considered a family or private matter, it is to be resolved at the
              family or community level. The elders will seek to keep the family
              together at all costs, disregarding the abuse the woman is being
              subjected to. Even the wife's family may choose to have their
              daughter continue to stay in the marriage as they do not want to
              pay back the dowry. Some women knowing such outcomes, choose to
              keep the domestic violence to themselves as the husband is usually
              angrier and more abusive after being reported.{' '}
            </p>{' '}
            <p>
              {' '}
              Women are also subjected to Gender Based Violence through rape. In
              most cases, they know their perpetrator but choose not to report
              because they are threatened not to or they don't want to go
              through the shame and stigma of being labelled a victim. In some
              communities, a young girl that has been raped is usually married
              off to the perpetrator. "My daughter was raped when she was 16
              years old. Since I am a widow, the family decided that since we
              know the perpetrator, we should force her to marry. I was upset
              that after my daughter endured such pain and humiliation, the best
              justice she can be given is to be given to a rapist as a wife. I
              refused and decided to take her back to school and she finished
              and joined university. My grandchild is now in school and my
              daughter has a bright future."Luckily, the woman was able to make
              a better choice for her daughter. Unfortunately, for many young
              girls, they are often forced to marry their perpetrator. This is
              an abuse of their child rights, disregards the violence they have
              already experienced and usually traps them in lifelong violence,
              as after marriage, they usually experience more abuse in multiple
              forms. Rape is not only carried out by members of the community
              but also by security forces. In remote areas such as Bentiu, women
              are left behind when men go to take care of cattle, leaving them
              at risk when security forces come at night.{' '}
            </p>{' '}
            <p>
              {' '}
              Women also face violence when trying to earn a living. They face
              sexual harassment at their work places or sexual exploitation in
              pursuit of employment. Women conducting businesses such as selling
              firewood or vegetables to earn a living, get their money taken by
              force by men. From these perspectives, women face insecurity in
              their homes, workplaces or within their community. It shows that
              Gender Based Violence is rooted in the patriarchal nature of our
              society and the cultural norms that perpetuate the inequality
              between men and women. The conflict and worsening economic
              conditions has exacerbated the situation and exposed women to even
              more instances of Gender based violence.
            </p>
            <p>
              The security sector as the police, justice system and health
              system has a fundamental role to play in addressing gender based
              violence. However, the security sector itself is faced with
              several challenges. The security sector is a male dominated field.
              Many police officers have the same discriminatory views about the
              roles and rights of women in the family. They may discourage
              victims from reporting to the formal system as they consider it a
              private matter. As they are males, victims may feel uncomfortable
              to open up to them about the abuse. As members of the community,
              women also fear reporting to them as they are usually friends or
              relatives of the perpetrator. Women are not just few in the
              security sector, they are often undervalued. They are usually
              placed in administrative posts and don't have as much
              opportunities to go for trainings. "For us service women, men
              scorn us that we cannot go for night patrols because we can be
              raped like the other women. So they leave us out even during out
              of country missions. In a group of ten, you may see only one woman
              or none i.e for international trainings" The rhetoric on peace and
              security calls for the inclusion of more women in the security
              sector. The presence of women in the security sector is not just
              because survivors often prefer to disclose their experience to a
              female officer. Having more women in the security sector and
              especially in key decision making positions builds trusts with the
              survivors that the case will be taken seriously. Thus, more
              survivors will be encouraged to report. Another challenge faced by
              the security sector is the lack of institutional capacity to deal
              with gender based violence. Few officers are trained on how to
              handle Gender Based Violence sensitively, and there is fear
              amongst survivors that their experience will not be kept
              confidential. There are few gender desks in areas such as Torit
              and there are no SGBV courts in Kuajok. Thus, even if a case is
              reported, the perpetrators still go free or are tried the
              customary way.
            </p>
            <h3 className="mt-3 mb-2">Security sector challenges</h3>
            <p>
              The women were also able to offer up some solutions to address
              gender-based violence. Recognizing the root as gender inequality,
              they recommended the empowerment of women especially through girl
              child education, economic empowerment of women and awareness
              raising on women’s rights. Before the dialogue, most of the women,
              including some in Juba were not aware of their rights or the 35%
              affirmative action. They also called for laws to be put in place
              that stop or abolish the negative cultural practices. The women
              also called for more dialogues that engaged men. It is important
              to have an attitude change from men as they are the main
              perpetrators and in their capacities as community leaders, they
              take the lead in responding to gender-based violence. The security
              sector should also be engaged through dialogues. Having been
              sensitized on security sector reform and the role of women in it,
              they called for security sector reform and for increased women
              representation in the sector. The women in the security sector, as
              well as their male counterparts, should get capacity building on
              how to handle and investigate gender-based violence. It is
              critical to end gender-based violence to enable South Sudanese
              women to fully participate in peace and nation building. Ending it
              is not easy but is possible if the right approaches are taken.
              Such meaningful dialogues that deepen our understanding of the
              issue and allow for community-based solutions are a step in the
              right direction.
            </p>
          </div>
        </div>
      </div>
      <div className="new_sec bg_light">
        <div className="container blog">
          <h4 className="mb-2">Latest Updates</h4>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="card">
                <img src="./image/blog1.png" alt="" className="blog_img_post" />
                <div className="p-2">
                  <h4>Women in Leadership</h4>
                  <p className="fn_12"> 27, April, 2023 &nbsp; 6 mins read</p>
                  <p className="blog_p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin quis scelerisque lectus. Aliquam ullamcorper feugiat
                    urna ut bibendum. Nulla eu erat sed ipsum. Lorem ipsum
                    dolor.
                  </p>
                  <div className="author_div">
                    <img src="./image/dummy female@2x.png" alt="" />
                    <div className="mt-2">
                      <h4 className="fn_14 ">Nyoka</h4>
                      <p className="fn_10 mig">Finance | Leadership Advocate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="card">
                <img src="./image/blog2.png" alt="" className="blog_img_post" />
                <div className="p-2">
                  <h4>
                    First aid response team with oversite on the humanitarian
                    program.
                  </h4>
                  <p className="fn_12"> 27, April, 2023 &nbsp; 6 mins read</p>
                  <p className="blog_p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin quis scelerisque lectus. Aliquam ullamcorper feugiat
                    urna ut bibendum. Nulla eu erat sed ipsum. Lorem ipsum
                    dolor.
                  </p>
                  <div className="author_div">
                    <img src="./image/dummy female@2x.png" alt="" />
                    <div className="mt-2">
                      <h4 className="fn_14 ">Nyoka</h4>
                      <p className="fn_10 mig">Finance | Leadership Advocate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="card">
                <img src="./image/blog3.png" alt="" className="blog_img_post" />
                <div className="p-2">
                  <h4>Women in Uniform Day</h4>
                  <p className="fn_12"> 27, April, 2023 &nbsp; 6 mins read</p>
                  <p className="blog_p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin quis scelerisque lectus. Aliquam ullamcorper feugiat
                    urna ut bibendum. Nulla eu erat sed ipsum. Lorem ipsum
                    dolor.
                  </p>
                  <div className="author_div">
                    <img src="./image/dummy female@2x.png" alt="" />
                    <div className="mt-2">
                      <h4 className="fn_14 ">Nyoka</h4>
                      <p className="fn_10 mig">Finance | Leadership Advocate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="card">
                <img src="./image/blog4.png" alt="" className="blog_img_post" />
                <div className="p-2">
                  <h4>Women Conference</h4>
                  <p className="fn_12"> 27, April, 2023 &nbsp; 6 mins read</p>
                  <p className="blog_p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin quis scelerisque lectus. Aliquam ullamcorper feugiat
                    urna ut bibendum. Nulla eu erat sed ipsum. Lorem ipsum
                    dolor.
                  </p>
                  <div className="author_div">
                    <img src="./image/dummy female@2x.png" alt="" />
                    <div className="mt-2">
                      <h4 className="fn_14 ">Nyoka</h4>
                      <p className="fn_10 mig">Finance | Leadership Advocate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="card">
                <img src="./image/blog5.png" alt="" className="blog_img_post" />
                <div className="p-2">
                  <h4>Intergenerational Dialouge on humans rights</h4>
                  <p className="fn_12"> 27, April, 2023 &nbsp; 6 mins read</p>
                  <p className="blog_p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin quis scelerisque lectus. Aliquam ullamcorper feugiat
                    urna ut bibendum. Nulla eu erat sed ipsum. Lorem ipsum
                    dolor.
                  </p>
                  <div className="author_div">
                    <img src="./image/dummy female@2x.png" alt="" />
                    <div className="mt-2">
                      <h4 className="fn_14 ">Nyoka</h4>
                      <p className="fn_10 mig">Finance | Leadership Advocate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="card">
                <img src="./image/blog6.png" alt="" className="blog_img_post" />
                <div className="p-2">
                  <h4>GBV Workshop</h4>
                  <p className="fn_12"> 27, April, 2023 &nbsp; 6 mins read</p>
                  <p className="blog_p">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin quis scelerisque lectus. Aliquam ullamcorper feugiat
                    urna ut bibendum. Nulla eu erat sed ipsum. Lorem ipsum
                    dolor.
                  </p>
                  <div className="author_div">
                    <img src="./image/dummy female@2x.png" alt="" />
                    <div className="mt-2">
                      <h4 className="fn_14 ">Nyoka</h4>
                      <p className="fn_10 mig">Finance | Leadership Advocate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Subscribe />
      <Link to="/donate">
        <div
          className="image_div1"
          style={{
            background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://sswcp.org/image/Group 56642.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-6  col-md-8 col-12 mx-auto d-flex justify-content-center  flex-column">
                <h1 className="title_text mb-2 text-light">
                  Help make a change!
                </h1>

                <div>
                  <Link to="/donate" className="btn btn-primary">
                    BECOME A SPONSOR
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 col-12 mx-auto"></div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SecurePage;
