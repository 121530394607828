import BlogPostCard from "./BlogPostCard";

const BlogPostList = ({ posts }) => {
  if (posts.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">No Blog Posts</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="card-columns">
          {posts &&
            posts.map((post) => <BlogPostCard key={post._id} post={post} />)}
        </div>
      </div>
    </div>
  );
};

export default BlogPostList;
