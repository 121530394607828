import React from 'react';
import './normal.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <div
        className="page_box"
        style={{
          background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/privacy+policy/sswcp+privacy.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="pop_text hh_t">
          <h1 className="text-center about_text ">Privacy Policy</h1>
        </div>
      </div>
      <div className="new_sec">
        {/* <div className="container">
          <p>Last updated: April 03, 2023 </p>{' '}
          <p>
            {' '}
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Free Privacy Policy
            Generator.
          </p>
          <h4>Interpretation and Definitions</h4>
          <h5>Interpretation</h5>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h5>Definitions</h5>
          <p>For the purposes of this Privacy Policy:</p>
          <ul className="pri_ul">
            <li>
              1. <b>Account</b> means a unique account created for You to access
              our Service or parts of our Service.{' '}
            </li>{' '}
            <li>
              {' '}
              2. <b>Affiliate</b> means an entity that controls, is controlled
              by or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest or other{' '}
            </li>{' '}
            <li>
              3. <b>Company</b> (referred to as either "the Company", "We", "Us"
              or "Our" in this Agreement) refers to South Sudan Women’s
              Coalition for Peace, Juba, South Sudan.{' '}
            </li>{' '}
            <li>
              {' '}
              4. <b>Cookies</b> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many{' '}
            </li>{' '}
            <li>
              {' '}
              5. <b>Country</b> refers to: South Sudan 6. Device means any
              device that can access the Service such as a computer, a cellphone
              or a digital tablet.{' '}
            </li>{' '}
            <li>
              {' '}
              7. <b>Personal</b> Data is any information that relates to an
              identified or identifiable individual. 8. Service refers to the
              Website.{' '}
            </li>{' '}
            <li>
              {' '}
              9. <b>Service</b> Provider means any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company.{' '}
            </li>{' '}
            <li>
              {' '}
              10. <b>Usage</b> Data refers to data collected automatically,
              either generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).{' '}
            </li>{' '}
            <li>
              {' '}
              11. <b>Website</b> refers to sswcp.org, accessible from
              www.sswcp.org
            </li>
          </ul>
          <p>
            You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </p>
          <h4>Contact Us </h4>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:{' '}
          </p>{' '}
          <p>
            {' '}
            By visiting this page on our website: http://www.sswcp.com/contact
          </p>
        </div> */}
        <div className="container privac">
          <h4>
            Privacy Policy for South Sudan Women's Coalition for Peace Website
          </h4>
          <p>
            At South Sudan Women's Coalition for Peace (SSWCP), we take the
            privacy and security of our website visitors seriously. This Privacy
            Policy outlines the type of information we collect from visitors and
            how we use, disclose, and protect it. By accessing and using our
            website, you agree to this Privacy Policy.
          </p>
          <h4>Collection of Information</h4>
          <p>We collect two types of information from our website visitors:</p>
          <p>
            a. Personal Information: We may collect personal information, such
            as your name, email address, phone number, and postal address, when
            you fill out a contact form, sign up for our newsletter, or make a
            donation. We use this information to respond to your inquiries, send
            you newsletters, and process your donations.
          </p>
          <p>
            b. Non-Personal Information: We also collect non-personal
            information, such as your browser type, operating system, and IP
            address, when you visit our website. We use this information to
            analyze trends, administer our website, and gather demographic
            information about our visitors.
          </p>
          <h4>Use of Information</h4>
          <p>We use the information we collect for the following purposes:</p>
          <p>
            a. To respond to your inquiries and provide you with information
            about our organization, activities, and events.
          </p>
          <p>b. To send you our newsletters and other promotional materials.</p>
          <p>
            c. To process your donations and provide you with donation receipts.
          </p>
          <p>d. To improve our website, products, and services.</p>
          <p>e. To comply with legal obligations.</p>
          <h4>Disclosure of Information</h4>
          <p>
            We do not sell, rent, or share your personal information with third
            parties except as described below:
          </p>
          <p>
            a. Service Providers: We may share your personal information with
            service providers who perform services on our behalf, such as email
            providers and payment processors. These service providers are only
            authorized to use your personal information as necessary to provide
            services to us.
          </p>
          <p>
            b. Legal Requirements: We may disclose your personal information if
            required by law, such as in response to a court order or subpoena.
          </p>
          <h4>Security</h4>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, and disclosure. We use secure servers
            and encryption technologies to store and transmit your personal
            information. However, no method of transmission over the internet or
            method of electronic storage is 100% secure, and we cannot guarantee
            the absolute security of your personal information.
          </p>
          <h4>Third-Party Links</h4>
          <p>
            Our website may contain links to third-party websites that are not
            under our control. We are not responsible for the privacy practices
            or content of these third-party websites. We encourage you to read
            their privacy policies before providing any personal information to
            them.
          </p>
          <h4>Children's Privacy</h4>{' '}
          <p>
            Our website is not intended for children under the age of 13. We do
            not knowingly collect personal information from children under the
            age of 13. If you are a parent or guardian and believe that your
            child has provided us with personal information, please contact us
            immediately.
          </p>
          <h4>Changes to this Privacy Policy</h4>
          <p>
            We may update this Privacy Policy from time to time. The updated
            Privacy Policy will be posted on our website with a new effective
            date. We encourage you to review this Privacy Policy periodically.
          </p>
          <h4>Contact Us</h4>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please <Link to="/contact" style={{color: 'green'}} >CONTACT US</Link>
          </p> {/*
          <p>
            I hope this Privacy Policy meets your needs. If you have any further
            questions or require any additional information, please let me know.
      </p> */}
        </div>
      </div>
      <div
        className="image_div1"
        style={{
          background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/privacy+policy/sswcp+You+can+create+a+smile.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-8  col-md-10 col-12 mx-auto d-flex justify-content-center  flex-column">
              <h1 className="title_text mb-2 text-light fn_50">
                You can create a smile in someone's life!
              </h1>

              <div>
                <Link to="/donate" className="btn btn-primary">
                  BECOME A SPONSOR
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-2 col-12 mx-auto"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
