import { memo } from 'react';
import { PortableText } from '@portabletext/react';
import PropTypes from 'prop-types';
import { urlFor } from "../../helper";

const BlogBody = memo(({ body }) => {
  const myPortableTextComponents = {
    types: {
      image: ({ value }) => {
        const imageUrl = urlFor(value.asset._ref).url();
        return (
          <img src={imageUrl} alt='blog' className='img-fluid' />
        )
      },
      callToAction: ({ value, isInline }) =>
        isInline ? (
          <a href={value.url}>{value.text}</a>
        ) : (
          <div className='callToAction'>{value.text}</div>
        ),
    },
    marks: {
      em: ({ children }) => <em className=''>{children}</em>,
      link: ({ children, value }) => {
        const target = value.href.startsWith('http') ? '_blank' : undefined;
        const rel = target === '_blank' ? 'noreferrer noopener' : undefined;

        return (
          <a href={value.href} target={target} rel={rel}>
            {children}
          </a>
        );
      },

      // Add any other custom marks you want to handle
    },
    block: {
      h1: ({ children }) => <h1 className='text-4xl px-2 my-8'>{children}</h1>,
      h2: ({ children }) => <h2 className='text-3xl px-2 my-6'>{children}</h2>,
      h3: ({ children }) => <h3 className='text-2xl px-2 my-4'>{children}</h3>,
      h4: ({ children }) => <h4 className='text-xl px-2 my-3'>{children}</h4>,
      h5: ({ children }) => <h5 className='text-lg px-2 my-2'>{children}</h5>,
      h6: ({ children }) => <h6 className='text-base px-2 my-1'>{children}</h6>,
      normal: ({ children }) => (
        <p className='text-base p-2'>{children}</p>
      ),
    },
    list: {
      bullet: ({ children }) => (
        <ul className='list-disc pl-10 pr-2 mb-4'>{children}</ul>
      ),
      number: ({ children }) => (
        <ol className='list-decimal pl-10 pr-2 mb-4'>{children}</ol>
      ),
      // Add any other custom list types you want to handle
    },
    listItem: {
      bullet: ({ children }) => <li className=''>{children}</li>,
      // Add any other custom list item types you want to handle
    },
    hardBreak: ({ node }) => (node === '\n' ? <br /> : null),
    unknownMark: () => null,
    unknownType: () => null,
    unknownBlockStyle: () => null,
    unknownList: () => null,
    unknownListItem: () => null,
  };

  return <PortableText value={body} components={myPortableTextComponents} />;
});

BlogBody.propTypes = {
  body: PropTypes.array.isRequired,
};

BlogBody.displayName = 'Icon';

export default BlogBody;
