import React from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { path } from '../../data/fdata';
import { HashLink } from 'react-router-hash-link';
import SearchComponent from '../SearchComponent';
const Navbar = () => {
  const pathname = useLocation().pathname;

  useEffect(() => {
    // let searchBoxCancel = document.querySelector(".search-box .bx-x");

    // searchBox.addEventListener('click', () => {
    //   navbar.classList.toggle('showInput');
    //   if (navbar.classList.contains('showInput')) {
    //     searchBox.classList.replace('bx-search', 'bx-x');
    //   } else {
    //     searchBox.classList.replace('bx-x', 'bx-search');
    //   }
    // });

    // sidebar open close js code
    let navLinks = document.querySelector('.nav-links');
    let menuOpenBtn = document.querySelector('.navbar .bx-menu ');
    let menuCloseBtn = document.querySelector('.nav-links .bx-x ');
    menuOpenBtn.onclick = function () {
      navLinks.style.left = '0';
    };
    menuCloseBtn.onclick = function () {
      navLinks.style.left = '-100%';
    };

    // sidebar submenu open close js code
    let htmlcssArrow = document.querySelector('.htmlcss-arrow');
    htmlcssArrow.onclick = function () {
      navLinks.classList.toggle('show1');
    };
    // let moreArrow = document.querySelector('.more-arrow');
    // moreArrow.onclick = function () {
    //   navLinks.classList.toggle('show2');
    // };
    let jsArrow = document.querySelector('.js-arrow');
    jsArrow.onclick = function () {
      navLinks.classList.toggle('show3');
    };
  }, []);

  function openNav() {
    document.getElementById('myNav').style.height = '100%';
  }

  function closeNav() {
    document.getElementById('myNav').style.height = '0%';
  }
  return (
    <>
      <header>
        <div>
          <nav>
            <div className="navbar">
              <i className="bx bx-menu"></i>
              <div className="logo">
                <Link to="/">
                  <img
                    src={`${path}/image/logo.png`}
                    alt=""
                    className="logoimg"
                  />
                </Link>
              </div>
              <div className="nav-links">
                <div className="sidebar-logo">
                  <span className="logo-name">
                    <Link to="/">
                      <img
                        src={`${path}/image/logo.png`}
                        alt=""
                        className="logoimg1"
                      />
                    </Link>
                  </span>
                  <i className="bx bx-x cur"></i>
                </div>
                <ul className="links">
                  <li>
                    <Link
                      to="/"
                      className={`${pathname === '/' ? 'activenav' : ''}`}
                    >
                      Home
                    </Link>
                  </li>

                  <li className="is_vis">
                    <Link
                      to="/about"
                      className={`${pathname === '/about' ? 'activenav' : ''}`}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/programs"
                      className={`${
                        pathname === '/programs' ? 'activenav' : ''
                      }`}
                    >
                      Our Programs
                    </Link>
                    <i className="bx bxs-chevron-down js-arrow arrow "></i>
                    <ul className="js-sub-menu sub-menu">
                      <li>
                        <HashLink
                          to={`/blog/#${encodeURIComponent('Peace Process')}`}
                        >
                          Peace Process
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          to={`/blog/#${encodeURIComponent(
                            'Capacity Building'
                          )}`}
                        >
                          Capacity Building
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          to={`/blog/#${encodeURIComponent(
                            'Advocacy and Lobby'
                          )}`}
                        >
                          Advocacy and Lobby
                        </HashLink>
                      </li>

                      <li>
                        <HashLink
                          to={`/blog/#${encodeURIComponent(
                            'Policy and Legislative'
                          )}`}
                        >
                          Policy and Legislative
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          to={`/blog/#${encodeURIComponent(
                            'Growth of Coalition'
                          )}`}
                        >
                          Growth of Coalition
                        </HashLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="is_vis">
                <Link to="/whatwedo">What We Do</Link>
              </li> */}
                  <li className="is_vis">
                    <Link
                      to="/blog"
                      className={`${pathname === '/blog' ? 'activenav' : ''}`}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/engagewithus"
                      className={`${
                        pathname === '/engagewithus' ? 'activenav' : ''
                      }`}
                    >
                      Engage With Us
                    </Link>
                    <i className="bx bxs-chevron-down htmlcss-arrow arrow  "></i>

                    <ul className="htmlCss-sub-menu sub-menu">
                      <li>
                        <Link to="/career">Careers</Link>
                      </li>
                      <li>
                        <HashLink to="/engagewithus#event">
                          Event Calendar
                        </HashLink>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname:
                              'https://sswcp.notion.site/Welcome-to-SSWCP-Surveys-d94c0111b5dc4fc6895e3829a8730d28?pvs=4',
                          }}
                          target="_blank"
                        >
                          Surveys
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="is_vis">
                    <Link to="/members">Our members</Link>
                  </li>

                  <li className="no_view">
                    <Link to="/donate" className="btn btn-danger text-light ">
                      Donate
                    </Link>
                  </li>
                  <li className="no_vis">
                    <Link to="/">More</Link>
                    {/* <i className="fa fa-chevron-down htmlcss-arrow arrow"></i> */}
                    <i className="bx bxs-chevron-down htmlcss-arrow arrow  "></i>
                    <ul className="htmlCss-sub-menu sub-menu">
                      <li>
                        <Link
                          to="/about"
                          className={`${
                            pathname === '/about' ? 'activenav' : ''
                          }`}
                        >
                          About Us
                        </Link>
                      </li>
                      {/* <li>
                    <Link to="/whatwedo">What We Do</Link>
                  </li> */}
                      <li>
                        <Link
                          to="/blog"
                          className={`${
                            pathname === '/blog' ? 'activenav' : ''
                          }`}
                        >
                          Blog
                        </Link>
                      </li>
                      {/* <li>
                    <Link to="/">Donate</Link>
                  </li> */}
                    </ul>
                  </li>

                  {/* Engage with us */}
                </ul>
              </div>
              <div className="search-box">
                <i className="bx bx-search" onClick={() => openNav()}></i>
                <Link to="/donate" className="btn btn-danger smal_dis">
                  Donate
                </Link>
                {/* <div className="input-box">
                <input type="text" placeholder="Search..." />
              </div> */}
              </div>
            </div>
          </nav>
        </div>

        <div id="myNav" className="overlay">
          <button className="closebtn text-light" onClick={() => closeNav()}>
            &times;
          </button>
          <SearchComponent />
        </div>
      </header>
    </>
  );
};

export default Navbar;
