import { getFileUrl } from '../helper';

export const CareersList = ({ careers }) => {
  return (
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th>Job Title</th>

            <th>Location</th>
            <th>Depertment</th>
            <th>Operation</th>
          </tr>
        </thead>
        {careers.length === 0 ? (
          <div className="container-fluid">
            <div className="row  flex justify-content-center w-full">
              <div className="col-12">
                <div>
                  <p>
                    There are currently no vacancies available please try again
                    later!
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <tbody className="cur_tbody">
            {careers.map((career, index) => {
              const { url, extension } = getFileUrl(career.pdfFile.asset._ref);

              return (
                <tr key={index}>
                  <td>{career.name}</td>
                  <td>{career.location}</td>
                  <td>{career.department}</td>
                  <td className="td_apply">
                    <a
                      href={`${url}?dl=${career.name}-SSWCP.${extension}`}
                      className="fn-color cur"
                    >
                      Apply
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};
