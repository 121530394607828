import EventOverview from "./Event";
export const ListEvents = ({ events }) => {
  if (events.length === 0) {
    return (
      <div class="flex p-2">
        <div class="card">
          <h3>No Events</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid">
      <div className="row mb-2">
        {events && events.map((event) => <EventOverview event={event} />)}
      </div>
    </div>
  );
};
