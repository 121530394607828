import React, { useState, useEffect, useRef } from "react";
import "./normal.css";
import toast, { Toaster } from "react-hot-toast";
import emailjs from '@emailjs/browser';


const DonatePage = () => {
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState('');
  const [phone, setNumber] = useState("");
  const [message, setMessage] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);

  const errorToast = (message) => toast.error(message);
  const successToast = (message) => toast.success(message);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const form = useRef();

  async function submitToAPI(e) {
    e.preventDefault();
    emailjs
      .sendForm('service_138ftjc','template_e4xpqhs', form.current,'JbC6yETtMNI4lRCiL')
      .then(
        (result) => {
          console.log(result.text);
          successToast('Donation form submitted!');
          setIsLoading(false);
        },
        (error) => {
          console.log(error.text);
          errorToast('Something went wrong, try again please!');
          setIsLoading(false);
        }
      );
  }
  return (
    <>
      <div
        className="contact"
        style={{
          background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/donate/sswcp+donate.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="contact_inn">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10 col-12 mx-auto order-lg-1 order-md-2 order-2">
                <div className="pay_card">
                  <p className="text-light mb-4">
                    Enter below details to donate
                  </p>
                  <form ref={form} onSubmit={submitToAPI}>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form_h"
                        placeholder="Name..."
                        name="name"
                        required
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form_h"
                        placeholder="Organization..."
                        name="organization"
                        required
                        onChange={(e) => setOrganization(e.target.value)}
                        value={organization}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form_h"
                        placeholder="Email..."
                        name="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="string"
                        class="form-control form_h"
                        placeholder="Phone no..."
                        name="phone"
                        required
                        onChange={(e) => setNumber(e.target.value)}
                        value={phone}
                      />
                    </div>
                    <div class="form-group">
                      <textarea
                        class="form-control"
                        rows="3"
                        name="message"
                        placeholder="Write Message"
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      ></textarea>
                    </div>
                    <div className="text-right">
                      <button className="btn btn-warning" onClick={submitToAPI}>
                        {isLoading ? 'Submitting...' : 'Submit to Donate'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 col-12 mx-auto order-lg-2 order-md-1 order-1">
                <h1 className="pay_text text-light text-left">
                  Your donation will make a difference!
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" />
    </>
  );
};

export default DonatePage;
