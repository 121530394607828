import React, { useState } from "react";
import { useEffect } from "react";
import Categories from "./Categories";
import sanityClient from "../../lib/sanity";

const BlogCategoryComp = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesLoading, setCategoryLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const toggleTab = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setCategoryLoading(true);
        const data = await sanityClient().queryCollection("category");
        if (data.length > 0) {
          setSelectedCategory(data[0]);
        }
        setCategories(data.sort((a, b) => a.index - b.index));
      } catch (err) {
        console.log("fetch category error", err);
      } finally {
        setCategoryLoading(false);
      }
    };
    fetchCategory();
  }, []);

  console.log(categories);

  return (
    <>
      <div className="tab_sec pt-5 pb-5">
        <div className="container">
          {categoriesLoading ? (
            <h1>Loading</h1>
          ) : (
            <Categories
              categories={categories}
              toggleTab={toggleTab}
              selectedCategory={selectedCategory}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BlogCategoryComp;
