import { useEffect, useState } from "react";
import sanityClient from "../../lib/sanity";
import toast, { Toaster } from "react-hot-toast";

export const MembersTable = () => {
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const errorToast = (message) => toast.error(message);
  const pageSize = 20; // Number of items per page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = members.slice(startIndex, endIndex);

  function handleNext() {
    setCurrentPage((prevPage) => prevPage + 1);
  }

  function handlePrevious() {
    setCurrentPage((prevPage) => prevPage - 1);
  }

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const data = await sanityClient().queryCollection("members");
        setMembersLoading(true);
        setMembers(data.sort((a, b) => a.index - b.index));
      } catch (err) {
        console.log("latest post fetch error", err);
        errorToast(err.message);
      } finally {
        setMembersLoading(false);
      }
    };

    fetchMembers();
  }, []);
  
  if(membersLoading) {
    return <p>Loading....</p>
  }
  return (
    <>
      <table className="table table-responsive table-striped">
        <thead>
          <tr style={{ background: "#e5fbe5" }}>
            <th>S/N</th>
            <th>Organizational/Company name</th>
            <th>Focal Person</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.length === 0 ? (
            <div>
              <p>No members available</p>
            </div>
          ) : paginatedData.map((v) => {
            return (
              <>
                <tr>
                  <td>{v.index}</td>
                  <td>{v.organization}</td>
                  <td>{v.name}</td>
                  <td>{v.email}</td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      <div class="flex justify-content-center">
        <ul class="pagination flex justify-content-between">
          <li class="page-item">
            <button class="btn btn-primary" onClick={handlePrevious}>
              Previous
            </button>
          </li>
          <li class="page-item">
            <button class="btn btn-primary" onClick={handleNext}>
              Next
            </button>
          </li>
        </ul>
      </div>
      <Toaster position="bottom-center" />
    </>
  );
};
