import axios from "axios";
import { app } from "../config";
const DATASET = "production";
const URL = `https://${app.sanityProjectId}.api.sanity.io/v2021-10-21/data/query/${DATASET}`;

const sanityClient = () => {
  return {
    queryCollection: async (schema, options = {}) => {
      const { include = "",  match = "" } = options;
      const QUERY = encodeURIComponent(
        `*[_type == "${schema}" ${match !== null ? `${match}`  : null}]${include}`
      );
      try {
        const { data } = await axios.get(`${URL}?query=${QUERY}`);
        return data?.result;
      } catch (err) {
        console.log("latest post fetch error", err);
      }
    },
    queryDocument: async (schema, slug, options = {}) => {
      const { include = ""} = options;
      const QUERY = encodeURIComponent(
        `*[_type == "${schema}" && slug.current == "${slug}"]${include}`
      );
      try {
        const { data } = await axios.get(`${URL}?query=${QUERY}`);
        return data?.result;
      } catch (err) {
        console.log("latest post fetch error", err);
      }
    },
  };
};

export default sanityClient;
