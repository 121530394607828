import React from 'react';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


/**
 * components
 */
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

/**
 * routes
 */
import Routes from './routes';

const App = () => {
  return (
    <>
      <Navbar />
      <Routes />
      <Footer />
    </>
  );
};

export default App;
