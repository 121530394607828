import React, { useState, useEffect } from "react";
import sanityClient from "../../lib/sanity";
import BlogPostsList from "./BlogPostsList";

const filterPostsByCategory = (posts, categoryName) => {
  return posts.filter((post) =>
    post?.categories?.some((category) => category.title === categoryName)
  );
};

const Categories = ({ categories, selectedCategory, toggleTab }) => {
  const [blogListLoading, setBlogListLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        setBlogListLoading(true);
        const posts = await sanityClient().queryCollection("post", {
          include: "{..., author->, categories[]->{title}}",
        });
        const filteredPosts = filterPostsByCategory(
          posts,
          selectedCategory?.title
        );
        setBlogListLoading(false);
        setBlogPosts(filteredPosts);
      } catch (error) {
        console.log("blog list fetch error", error);
        setBlogListLoading(false);
      }
    };

    if (selectedCategory !== null) {
      fetchBlogList();
    }
  }, [selectedCategory]);

  return (
    <>
      <div className="border-bottom border-dark">
        {categories.map((category, index) => {
          return (
            <a
              href={`#${category.title}`}
              key={category.index}
              className={
                selectedCategory !== null && selectedCategory._id === category._id
                  ? "btn btn-link btn-active"
                  : "btn"
              }
              onClick={() => toggleTab(category)}
              style={{ background: "#fff" }}
            >
              {category.title}
            </a>
          )
        })}
      </div>

      {blogListLoading ? (
        <p>Loading posts</p>
      ) : (
        <div className="content-tabs">
          <div
            className={
              selectedCategory !== null && selectedCategory?._id !== null
                ? "tabcontent  active-content"
                : "tabcontent"
            }
          >
            <BlogPostsList posts={blogPosts} />
          </div>
        </div>
      )}
    </>
  );
};

export default Categories;
