export const aboutFaq = {
  title: '',
  rows: [
    {
      title: "What is South Sudan Women's Coalition for Peace?",
      content:
        "South Sudan Women's Coalition for Peace is a network of women's organizations and individuals advocating for peace, gender equality, and women's rights in South Sudan.",
    },
    {
      title: 'Who are the members of South Sudan Women’s Coalition for Peace?',
      content:
        'The members of South Sudan Women’s Coalition for Peace are individuals with an active subscription status within the coalition. Check all registered active members here.       ',
    },
    {
      title:
        "What are the main goals of South Sudan Women's Coalition for Peace?",
      content:
        'The Coalition aims to promote and protect the rights of women and girls, advocate for their inclusion in peacebuilding processes, and work towards ending violence against women in South Sudan.      ',
    },
    {
      title:
        "How does the South Sudan Women's Coalition for Peace work towards achieving peace?      ",
      content:
        "The Coalition engages in advocacy, lobbying, and networking activities to promote peacebuilding efforts that prioritize women's participation and leadership. The Coalition also conducts research and documentation to inform policy and programming on women's peace and security issues.      ",
    },
    {
      title:
        "How does South Sudan Women's Coalition for Peace support women and girls affected by conflict?      ",
      content:
        'The Coalition provides psychosocial support and referral services for survivors of gender-based violence, advocates for their access to justice, and supports the provision of essential services such as healthcare and education in conflict-affected areas.        ',
    },
    {
      title:
        "How can I get involved with South Sudan Women's Coalition for Peace?",
      content:
        "You can get involved with the Coalition by becoming a member, volunteering your time and expertise, donating to support their activities, or advocating for women's rights and peacebuilding in South Sudan. To become a member, refer to our Membership page.        ",
    },
    {
      title:
        "What challenges does South Sudan Women's Coalition for Peace face?",
      content:
        "The Coalition faces several challenges, including limited funding, insecurity, and limited opportunities for women's meaningful participation in peace processes.",
    },
    {
      title:
        "How does South Sudan Women's Coalition for Peace collaborate with other organizations and stakeholders?",
      content:
        "The Coalition works with a wide range of partners, including civil society organizations, government institutions, and international organizations, to advance women's peace and security in South Sudan.",
    },
    {
      title:
        "What is the role of men in South Sudan Women's Coalition for Peace?",
      content:
        "The Coalition recognizes the important role that men play in advancing gender equality and promoting peace. As such, the Coalition works with men and boys to promote their engagement in efforts to end violence against women and promote women's rights.        ",
    },
    {
      title:
        "How does South Sudan Women's Coalition for Peace ensure the participation of marginalized women in their activities?",
      content:
        'The Coalition strives to ensure the participation of marginalized women, including those from rural areas and ethnic minorities, in their activities through targeted outreach, capacity building, and advocacy efforts',
    },
    {
      title:
        "What impact has the South Sudan Women's Coalition for Peace had on women's rights and peacebuilding in South Sudan?",
      content:
        "The Coalition has made significant contributions to advancing women's rights and peacebuilding in South Sudan. Its advocacy efforts have led to the inclusion of women in key peace processes, and its programming has supported women's access to essential services and their participation in leadership roles. However, much more work is needed to address the complex challenges facing women and girls in the country.        ",
    },
  ],
};
export const companydata = [
  {
    name: 'Eve Organization for Women Development',
    focal: 'Eva Kiongo',
    email: 'info@evesouthsudan.org',
    index: 1,
  },
  {
    name: 'Vulnerable Women and Children, Women',
    focal: 'Coleta Aku',
    email: 'coletaaku@gmail.com',
    index: 2,
  },
  {
    name: "NBeG Women's General Association",
    focal: 'Elizabeth Ajok',
    email: 'elizamakerchol@gmail.com',
    index: 3,
  },
  {
    name: 'She Matters',
    focal: 'Shodier Bol',
    email: 'shodierBLoth@gmail.com',
    index: 4,
  },
  {
    name: 'South Sudan Women in Kampala',
    focal: 'Mary Taban',
    email: 'mtaban2015@gmail.com',
    index: 5,
  },
  {
    name: "Women's International Peace Centre",
    focal: 'Juliet Were',
    email: 'program@wipc.org',
    index: 6,
  },
  {
    name: 'National Transformation Leadership Institute',
    focal: 'Angelina Bazugba',
    email: 'amattijo@gmail.com',
    index: 7,
  },
  {
    name: 'Women and Youth Empowerment initiative',
    focal: 'Zainab Osman',
    email: 'info.wayei@gmail.com',
    index: 8,
  },
  {
    name: 'Women For Justice and Equality',
    focal: 'Zabib Musa Loro',
    email: 'idrassudan.org@gmail.com',
    index: 9,
  },
  {
    name: 'Mother and Children Development Aid -MaCDA',
    focal: 'Jackline Warille',
    email: 'jacklinebenjamin@yahoo.com',
    index: 10,
  },
  {
    name: 'South Sudan Democratic Engagement Monitoring and Observation Program(SSUDEMOP)',
    focal: 'Lorna Merkaje',
    email: 'merekaje@gmail.com',
    index: 11,
  },
  {
    name: 'Crown the Women',
    focal: 'Riya William Yuyada',
    email: 'nja@crownthewoman.org',
    index: 12,
  },
  {
    name: 'Manzora Crafts Center',
    focal: 'Angela Leke',
    email: 'endreoangela72@gmail.com',
    index: 13,
  },
  {
    name: 'Girls Power Mentorship Program',
    focal: 'Eva Christo',
    email: 'mentorshipgirlspower@gmailcom',
    index: 14,
  },
  {
    name: 'Nyozovuku Women Group (refugee women)',
    focal: 'Keji Lona',
    email: 'neimagrace2@gmail.com',
    index: 15,
  },
  {
    name: 'SS Women with disability network',
    focal: 'Atim Caroline',
    email: 'sswdnetwork@gmail.com',
    index: 16,
  },
  {
    name: 'Itwak Women',
    focal: 'Davidica Ikai',
    email: 'davidicaikai@yahoo.com',
    index: 17,
  },
  {
    name: 'Uror Women Peace Initiative',
    focal: 'Nuba Chatim Peres',
    email: 'peresnuba@yahoo.com',
    index: 18,
  },
  {
    name: 'Voice for Change',
    focal: 'Lona James',
    email: 'lona2017.elia@gmail.com',
    index: 19,
    
  },
  {
    name: 'Peace Canal',
    focal: 'Mary Aran Gol',
    email: 'Mary.dut@pofss.org',
    index: 20,
  },
  {
    name: 'South Sudan Women General Association',
    focal: 'Zeinab Yassin',
    email: 'hagelsafi@gmail.com',
    index: 21,
  },
  {
    name: 'South Sudan Women Consortium',
    focal: 'Dr. Betty Achan Ogwaro',
    email: 'bogwaro@gmail.com',
    index: 22,
  },
  {
    name: 'Agape Organization',
    focal: 'Suzy Michiek',
    email: 'syzymou@gmail.com',
    index: 23,
  },
  {
    name: 'South Sudanese Youth in Uganda',
    focal: 'Victoria Kenyi',
    email: 'kenyivictoriamartin2@gmail.com',
    index: 24,
  },
  {
    name: 'Anafii',
    focal: 'Flora Bringi',
    email: 'anafiiconsultancy.enterprise@gmail.com',
    index: 25,
  },
];

export const individuals = [
  {
    individual: 'Elizabeth Ajok Atak Kuan',
    email: 'elizamakerchol@gmail.com',
    index: 1,
  },
  {
    individual: 'Dorcus Amol',
    email: 'dorcusamol3@gmail.com',
    index: 2,
  },
  {
    individual: 'Thecle Felicite Soro - ANI',
    email: 'theclesoro@gmail.com',
    index: 3,
  },
  {
    individual: 'Veronica Lucy',
    email: 'lucymako90@gmail.com',
    index: 4,
  },
  {
    individual: 'Gloria Poni Roman',
    email: 'gloriaponirm@gmail.com',
    index: 5,
  },
  {
    individual: 'Nyuon Susan Sebit',
    email: 'desebt@gmail.com',
    index: 6,
  },
  {
    individual: 'Joyce Maker',
    email: 'makerjoyce@ymail.com',
    index: 7,
  },
  {
    individual: 'Minagano Lydia',
    email: 'minagano.kape@gmail.com',
    index: 8,
  },
  {
    individual: 'Dr Koiti Emmily',
    email: 'koitiemmily09@gmail.com',
    index: 9,
  },
  {
    individual: 'Vicky Juan Angelo',
    email: 'Vicky.angelo2017@gmail.com',
    index: 10,
  },
  {
    individual: 'Vicky Amal Pax Kamilo',
    email: 'VickyKamilo42@gmail.com',
    index: 11,
  },
  {
    individual: 'Stella Matutina Henry Bagho',
    email: 'stellairama73@gmail.com',
    index: 12,
  },
  {
    individual: 'Tabitha Manibe',
    email: 'tkrobah@gmail.com',
    index: 13,
  },
  {
    individual: 'Dr Siham Osman',
    email: 'siham75osman@gmail.com',
    index: 14,
  },
  {
    individual: 'Sunday Andrea',
    email: 'sundaylojong@gmail.com',
    index: 15,
  },
  {
    individual: 'Sandra Bona Malual',
    email: 'sudisandra69@gmail.com',
    index: 16,
  },
  {
    individual: 'Apuk Ayuel',
    email: 'aayuel@gmail.com',
    index: 17,
  },
  {
    individual: 'Prof. Julia Duany',
    email: 'juliaduany2@gmail.com',
    index: 18,
  },
  {
    individual: 'Prof Pauline Riak',
    email: 'yirol.surda@gmail.com',
    index: 19,
  },
  {
    individual: 'Keji Martina',
    email: 'kejimartina@gmail.com',
    index: 20,
  },
  {
    individual: 'Grace Andrua',
    email: 'graceandrua@gmail.com',
    index: 21,
  },
  {
    individual: 'Martha Batim',
    email: 'marthabatim@gmail.com',
    index: 22,
  },
  {
    individual: 'Panda Machol',
    email: 'macholpanda@gmail.com',
    index: 23,
  },
  {
    individual: 'Mary Nyanut',
    email: 'marynyanut2020@gmail.com',
    index: 24,
  },
  {
    individual: 'Neima Grace',
    email: 'neimagrace@gmail.com',
    index: 25,
  },
  {
    individual: 'Dr. Florence Bakowa',
    email: 'fbakowa@yahoo.com',
    index: 26,
  },
];
export const path = 'https://sswcp.org';
