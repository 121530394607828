import React from 'react';
import './Programs.css';
import ProgCompo from '../../components/program/program';
import ModalD from '../../components/donateModal/donateModal';

const Programs = () => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);
  return (
    <>
      <ModalD />
      <ProgCompo />
    </>
  );
};

export default Programs;
