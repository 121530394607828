import React, { useEffect, useState } from "react";
import sanityClient from "../../lib/sanity";
import { urlFor } from "../../helper";
import { Link } from "react-router-dom";
import LatestBlogPostsList from "./LatestBlogPostsList";

const MainPostCard = ({ post }) => {
  const imageUrl = urlFor(post.mainImage).url();
  return (
    <div className="col-lg-6 col-md-6 col-12">
      <Link to={`/blog/${post?.slug?.current}`} class="card_link">
        <div className="card">
          <img
            src={imageUrl}
            alt={post.title}
            className="what_we_do_img ex_h"
          />
          <div className="up_card_text home-card-cta">
            <h4 className="text-truncate" style={{ textOverflow: "ellipsis" }}>{post.title}</h4>
            <Link to="/blog">
              <i class="bx bx-right-arrow-circle"></i>
            </Link>
          </div>
        </div>
      </Link>
    </div>
  );
};

const MainPostsList = ({ posts }) => {
  if (posts.length === 0) {
    return (
      <div className="card mb-2">
        <div className="card-body">
          <h3 className="card-title">No Blog Posts</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="row mb-2">
      {posts &&
        posts.map((post, index) => <MainPostCard key={`${index}-${post.id}`} post={{ ...post }} />)}
    </div>
  );
};

const HomeBlogPosts = () => {
  const [latestPosts, setLatestPosts] = useState([]);
  const [mainPosts, setMainPosts] = useState([]);

  useEffect(() => {
    const latestPosts = async () => {
      try {
        const data = await sanityClient().queryCollection("post", {
          include: "{..., author->, categories[]->{title}}",
          sort: {},
        });
        setLatestPosts(data.slice(2, data.length));
        setMainPosts(data.slice(0, 2));
      } catch (err) {
        console.log("latest post fetch error", err);
      }
    };

    latestPosts();
  }, []);

  return (
    <div className="new_sec bg_light">
      <div className="container">
        <h3 className="title_text text-center mb-5">Latest Updates</h3>
        <MainPostsList posts={mainPosts} />
        <LatestBlogPostsList posts={latestPosts} />
      </div>
    </div>
  );
};

export default HomeBlogPosts;
