import React from "react";
import { dateFormat, urlFor } from "../../helper";

const LatestBlogPostCard = ({ post }) => {
  const imageUrl = urlFor(post.mainImage).url();
  const authorImageUrl = urlFor(post.author.image.asset._ref).url();
  return (
    <div className="row align-items-center ">
      <div className="d-flex flex-grow-1 col-md-6 col-12">
        <img
          src={imageUrl}
          alt={post.title}
          className="img-fluid"
          style={{ objectFit: "cover", height: "100%", aspectRatio: "4/3" }}
        />
      </div>
      <div className="col-md-6 col-12">
        <h1 className="font-weight-bold">
          <a className="text-dark" href={`/blog/${post.slug.current}`}>
            {post.title}
          </a>
        </h1>
        <p className="mb-2">{post.description}</p>
        <div className="d-flex align-items-center">
          <p className="text-secondary mr-2">
            {dateFormat(post._createdAt, "DD, MMMM, YYYY")}
          </p>
          <span className="badge badge-light text-secondary">{post.categories[0].title}</span>
        </div>
        <div className="author_div">
          <figure className="mr-2">
            <img
              className="border border-primary"
              src={authorImageUrl}
              alt="author"
              style={{ borderStyle: "double" }}
            />
          </figure>

          <h5>{post.author.name}</h5>
        </div>
      </div>
    </div>
  );
};

export default LatestBlogPostCard;
