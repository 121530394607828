import React from 'react';
import './About.css';
import HeroAbout from '../../components/about/HeroAbout';
import { useEffect } from 'react';
import ModalD from '../../components/donateModal/donateModal';
const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <ModalD />
      <HeroAbout />
    </>
  );
};

export default About;
