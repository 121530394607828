import React, { useEffect, useState } from "react";
import "./Blog.css";
import BlogCompo from "../../components/blog/BlogCompo";
import ModalD from "../../components/donateModal/donateModal";
import LatestBlogPostCard from "../../components/blog/LatestBlogPostCard";
import toast, { Toaster } from "react-hot-toast";
import sanityClient from "../../lib/sanity";

const Blog = () => {
  const [latestPost, setLatestPost] = useState([]);
  //const [error, _setError] = useState(false);
  const [latestPostLoading, setLatestPostLoading] = useState(false);
  const errorToast = (message) => toast.error(message);

  useEffect(() => {
    const latestPost = async () => {
      try {
        const data = await sanityClient().queryCollection("post", {
          include: "{..., author->, categories[]->{title}}",
          sort: {}
        });
        setLatestPostLoading(true);
        setLatestPost(data.slice(0, 1));
      } catch (err) {
        console.log("latest post fetch error", err);
        errorToast(err.message);
      } finally {
        setLatestPostLoading(false);
      }
    };

    latestPost();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="new_sec blog_100_sec">
        <div className="container h-100">
          {latestPostLoading ? (
            <div>Latest Post Loading...</div>
          ) : (
            latestPost &&
            latestPost.map((post) => (
              <LatestBlogPostCard post={post} key={post._id} />
            ))
          )}
        </div>
      </div>
      <ModalD />
      <BlogCompo />
      <Toaster position="bottom-center" />
    </>
  );
};

export default Blog;
