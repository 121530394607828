import { useState } from "react";
import sanityClient from "../lib/sanity";
import { Link } from "react-router-dom";

const SearchResult = ({ post }) => {
  return <Link to={`/blog/${post?.slug?.current}`}>{post.title}</Link>;
};

const SearchComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    handleSearch(e.target.value);
  };

  const handleSearch = async (query) => {
    try {
      const results = await sanityClient().queryCollection("post", {
        include: "{..., author->, categories[]->{title}}",
        match: `&& (title match "${query}" || body match "${query}")`,
      });

      setSearchResults(results);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <div className="overlay-content">
      <div>
        <form className="search_box">
          <input
            type="text"
            placeholder="Search your keyword"
            name="search"
            required
            onChange={handleSearchChange}
            value={searchQuery}
          />
        </form>
        <div style={{ color: "white" }} className="mt-2 w-full">
          {searchResults &&
            searchResults.map((post) => (
              <SearchResult key={post._id} post={{ ...post }} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
