import BlogCategoryComp from './BlogCategoryComp';
import Subscribe from '../calltoaction/Subscribe';

const BlogCompo = () => {
  return (
    <>
      <BlogCategoryComp />
      <Subscribe />
    </>
  );
};

export default BlogCompo;
