import React, { useEffect, useState } from "react";
import "./normal.css";
import { Link } from "react-router-dom";
import sanityClient from "../../lib/sanity";
import toast, { Toaster } from "react-hot-toast";
import { ListAnnualReports } from "../../components/ListReports";


const Annual = () => {
  const [reports, setReports] = useState([]);
  const [latestPostLoading, setReportsLoading] = useState(false);
  const errorToast = (message) => toast.error(message);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const data = await sanityClient().queryCollection("report");
        setReportsLoading(true);
        setReports(data);
      } catch (err) {
        console.log("latest post fetch error", err);
        errorToast(err.message);
      } finally {
        setReportsLoading(false);
      }
    };

    fetchReports();
  }, []);

  console.log(latestPostLoading);

  return (
    <>
      <div className="annual">
        <div className="container">
          <h1 className="title_text_Sm">Activity & Annual Reports</h1>
          <ListAnnualReports reports={reports} />
        </div>
      </div>
      <div
        className="image_div1"
        style={{
          background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/annual+report/Be+part+of+a+community+that+empowers.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-8  col-md-10 col-12 mx-auto d-flex justify-content-center  flex-column">
              <h1 className="title_text mb-2 text-light fn_50">
                You can create a smile in someone's life!
              </h1>
              <div>
                <Link to="/career" className="btn btn-primary">
                  Apply To Join
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-2 col-12 mx-auto"></div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default Annual;
