import React from 'react';
import Faq from 'react-faq-component';
import { aboutFaq } from '../../data/fdata';
const FaqCompo = () => {
  const styles = {
    // bgColor: 'white',

    rowTitleColor: '#0B6240',
    rowTitleTextSize: '20px',
    rowContentTextSize: '14px',
    arrowColor: 'green',
  };

  const config = {
    animate: true,
    arrowIcon: 'V',
    openOnload: 0,
    expandIcon: '+',
    collapseIcon: '-',
  };
  return (
    <>
      <Faq data={aboutFaq} styles={styles} config={config} />
    </>
  );
};

export default FaqCompo;
