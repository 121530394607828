import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import FaqCompo from './FaqCompo';
import Footer from '../footer/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const achievements = [ 
  {
    text: 'Acknowledgment of the Coalition as a key stakeholder and signatory to the R-ARCSS',
  },
  {
    text: 'Inclusion of 4 women members of the Coalition as delegates to the HLRF process.',
  },
  {
    text: 'The Coalition demanded for 50-50 gender parity, however achieved at least 35% women representation and one-woman vice president in the transitional period. We acknowledge this increase but strongly assert that we will push until we eventually secure the 50-50-gender parity we seek in all levels of government.',
  },
  {
    text: 'Issued a number of Press Statements and Position Papers on the Revitalization process of the Agreement on the Resolution of Conflict in South Sudan (ARCSS).',
  },
  {
    text: 'Acknowledgment of the Coalition as a key stakeholder and signatory to the agreement - Agreement on the Cessation of Hostilities (ACoH) -2017.',
  },
  {
    text: 'Called severally for the release of Dr. Riek Machar to ensure realization of an all-inclusive and sustainable peace in South Sudan § Secured lobby meetings with key entities and individuals such as the Office of the Special Envoy on Women, Peace and Security, and the office of the Director for Women, Gender and Development at the African Union.',
  },
  {
    text: 'Engaged key guarantors of the peace process including the Troika Special Envoys and the European Union and a number of diplomatic missions in Addis Ababa.',
  },
  {
    text: 'Held continuous prayers and peaceful marches during the HLRF process in Juba, Kenya, Uganda and Egypt. § Lobby key regional government officials including the Office of the South Sudan Ambassador to Uganda and delivering the communiqué that listed their demands.',
  },
  {
    text: 'A team of 5 women engaged in advocacy work as well as providing technical analysis and support to the delegates who were participating in the Addis peace talks.',
  },
  {
    text: 'Consulted by IGAD as part of the Shuttle Diplomacy on the Peace Process.',
  },
  {
    text: 'The coalition convened the first ever virtual summit dubbed #SawaSouthSudan which garnered the participation of eminent world leaders including former heads of states, peace mediators and refugees as speakers in a bid to call for a decisive end to the South Sudanese conflict.',
  },
  {
    text: 'Establishment of a functioning Secretariat of the Coalition’s in Juba.',
  },
  {
    text: 'The coalition has a countrywide reach and has trained over 1000 peace committee members in Aweil, Nyamalel, Bentiu PoC and town, Yei, Torit, Rumbek, Awerial, Bor, Nimule and Juba.',
  },
  {
    text: 'Members of the Coalition have represented South Sudan at important and high-level meetings in Oslo, New York, Washington DC, Nairobi, Ottawa, Toronto, South Africa, Burundi, Poland, Senegal, Zimbabwe, Botswana, Kampala and the UK.',
  },
  {
    text: 'The Coalition enjoys association with global networks of women who are also working for sustainable peace.',
  },
  {
    text: 'Four of the coalition members are represented in key implementation mechanisms of the R-ARCSS. This includes the Revitalized Joint Monitoring and Evaluation Commission (R-JMEC), the National Constitution Amendment Committee (NCAC), the Ceasefire and Transitional Security Arrangement Monitoring and Verification Mechanism (CTSAMVM) Board and Strategic Defense and Security Review (SDSR) – Board.',
  },
];

const AboutMore = ({ close }) => {
  const [expandOne, setExpandOne] = useState(false);
  const [expandTwo, setExpandTwo] = useState(false);
  const his = useHistory();

  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: false,
  };
  return (
    <div className='pop_up_box'>
      <div
        className='pop_box_hero'
        style={{
          background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/about+us/sswcp+innter+about.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='icon_arrow'>
        <img
              src='./image/back.png'
              onClick={() => { 
                his.goBack(); 
              }}
              className='cur'
              width='50px'
              alt=''
            />
        </div>
        <div className='pop_text '>
          <h1 className='text-center'>About Us</h1>
        </div>
      </div>
      <div className='container pb-5'>
        <p className='mt-4 fn_18'>
          <b>
            The South Sudan Women Coalition for Peace (SSWCP) is a coalition of
            over 50 women and women-led organizations from South Sudan and the
            regional countries of Kenya and Uganda. Formed in September 2017,
            the coalition brings together South Sudanese women irrespective of
            their political affiliation, religious, socioeconomic status, and
            issue-related divides including those from refugee camps and
            non-government-controlled areas.
          </b>
        </p>
        <p>
          The overall objective of the Coalition is to build a strong Women’s
          Movement in South Sudan, which will be able to address a wide range of
          issues under women, peace and security undermining their effective
          engagement on matters affecting their lives, including insecurity,
          underrepresentation in leadership and decision-making structures,
          national development and peace building. The coalition was co-created
          by three institutions namely, EVE Organization for Women Development,
          The National Transformational Leadership Institute and the South Sudan
          Women General Association (SSWGA).
          {expandOne ? (
            <>
              The Women Association is an umbrella organization with presence in
              all states- from national to grassroots levels, while EVE has
              presence in South Sudan and other East African countries – Kenya
              and Uganda, making possible to reach out women in the refugees’
              camps and urban areas who were displaced by conflict in the
              country. Since inception, the three institutions have worked hard
              to facilitate communication and collection of women’s common
              agenda for peace and participation in the on-going peace building
              processes, which is major goal by which the coalition is premised.
              Since September 2017, the Coalition has been actively engaged in
              building trust among women through awareness raising and
              information and experience sharing develop common positions.
              Organizing joint activities such as peace march and prayers. These
              activities have continued to strengthen their demands and pushed
              for their inclusion and meaningful participation in the High-
              Level Revitalization Forum (HRLF).
              <b
                onClick={() => setExpandOne(false)}
                style={{ cursor: 'pointer' }}
              >
                &nbsp; Read Less
              </b>
            </>
          ) : (
            <b onClick={() => setExpandOne(true)} style={{ cursor: 'pointer' }}>
              &nbsp;...Read More
            </b>
          )}
        </p>

        <h4>SSWCP Background</h4>
        <div>
          <p>
            In June 2017, the IGAD Assembly of Heads of State and Government
            decided inter alia to revitalize the Agreement on the Resolution of
            the Conflict in South Sudan (ARCSS) and entrusted the mandate to the
            IGAD Council of Ministers. The IGAD Council of Ministers were tasked
            “to discuss concrete measures to restore permanent cease fire, to
            full implementation of the peace agreement, and to develop a revised
            and realistic timeline and implementation schedule towards a
            democratic election at the end of the transition period. Based on
            this decision and under the leadership of Amb. Ismail Wais, the
            Special Envoy for South Sudan, the High-Level Revitalization Forum
            (HLRF) was launched in December 2017 to find solution to the ongoing
            conflict and humanitarian crisis in South Sudan by bringing to the
            table the Government and the Opposition armed and non-armed groups
            to discuss the way forward towards the full and timely
            implementation of the peace agreement. </p> {expandTwo ? (
            <>
              <p>
                Women in South Sudan participated in the process that led to the
                ARCSS. However, their participation in the implementation phase
                was almost non-existent. Commitments made in the agreement have
                not been met in terms of women representation in the
                Transitional Government of National Unity (TGONU), while most
                parts of the agreement remained unimplemented. With these
                realities and the renewed conflict in July 2016 that led to the
                massive killing, raping and abuses of women and girls, women in
                South Sudan were left shocked and shaken. They realized that
                more consolidated efforts is needed if these new challenges is
                to be tackled and addressed in a structured and coordinated
                manner. With experiences and lessons learnt from the ARCSS,
                Women decided to consult and regroup and get back to the drawing
                board. In September 2017 and January 2018 at the wake of the
                revitalization process, a number of 46 South Sudanese women
                organizations from South Sudan, Kenya and Uganda including those
                in the Refugee camps convened in Entebbe Uganda convened
                regional meetings in Entebbe to consult, challenge, console each
                other and analyzed the opportunities and way forward on how
                women can objectively participate in the revitalization process.
                The convening resulted in a formation of a loose women coalition
                with an overall objective of building a strong voice that would
                put women’s interest in the forefront and push for inclusivity
                and meaningful representation during the revitalization process
                and any future processes of nation building. As a result, more
                women were invited to participate in the revitalization process
                since December 2017 and their advocacy and push for more
                inclusivity in the process was evident. The second phase of the
                revitalization process saw an increase of women in both the
                government and different opposition parties and a number of
                gender aspects were reflected in the resultant document.
              </p>
              <p>
                This has given a boost and a glimpse of hope that if given the
                necessary support, the coalition can even do better throughout
                the peace process and set South Sudanese women on the forefront
                of resolving the conflict as well as going beyond that. However,
                this requires more support, capacity building, popularization of
                the coalition and a number of other follow up activities to
                strengthen this important movement. It is important to also note
                that the women movement in South Sudan is in its nascent stage 2
                and didn't get the support it needed to blossom amidst the
                series of challenging events that the country is going through,
                therefore initiatives such as the women coalition that managed
                to bring in all the major women groups together needs to be
                nourished to flourish, hence the need to aim at both short term
                and long term support and planning for a sustainable peace and
                democracy in South Sudan.
                <b
                  onClick={() => setExpandTwo(false)}
                  style={{ cursor: 'pointer' }}
                >
                  &nbsp; Read Less
                </b>
              </p>
            </>
          ) :(
            <b onClick={() => setExpandTwo(true)} style={{ cursor: 'pointer' }}>
              ...Read More
            </b>
          )}
        </div>
      </div>

      <div className='about_more_slide' style={{textAlign:"center"}}>
        <h4>
          <b>
            Some Key achievements of the South Sudan Women Coalition for Peace
          </b>
        </h4>
        <Slider {...settings}>
          {achievements.map(({ text }, index) => {
            return (
              <div key={index} className='ach_slide'>
                {text}
              </div>
            );
          })}
        </Slider>
      </div>
          <br></br>
      <div className='container pb-5'>
        <div className='text-center'>
          <button className='btn btn-primary'>
            Frequently Asked Questions
          </button>
        </div>
        <div className='mt-5 faq_sec'>
          <FaqCompo />
        </div>
        <div className='text-center mb-5 mt-5'>
          <h1>Still have questions?</h1>
          <p>
            If you cannot find answer to your questions? Please feel free to
            <Link to='/contact' style={{color: 'darkgreen'}}> CONTACT US!</Link> 
          </p>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-12  mb-2'>
            <Link to='/engagewithus' className='card_link'>
              <div className='card'>
                <img
                  src='https://s3.amazonaws.com/sswcp.org/image/inner/about+us/engage+with+us.webp'
                  alt=''
                  className='what_we_do_img ex_h'
                />
                <div className='card-cta'>
                  <h4>Engage With Us</h4>

                  <i className='bx bx-right-arrow-circle' style={{marginBottom: ".5rem"}}></i>
                </div>
              </div>
            </Link>
          </div>
          <div className='col-lg-6 col-md-6  col-12  mb-2'>
            <Link to='/members' className='card_link'>
              <div className='card'>
                <img
                  src='https://s3.amazonaws.com/sswcp.org/image/inner/about+us/our+members.webp'
                  alt=''
                  className='what_we_do_img ex_h'
                />
                <div className='card-cta'>
                  <h4>Our Members</h4>

                  <i className='bx bx-right-arrow-circle' style={{marginBottom: ".5rem"}}></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutMore;
