import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { path } from "../../data/fdata";
import sanityClient from "../../lib/sanity";
const SearchResult = ({ post }) => {
  return <Link to={`/blog/${post?.slug?.current}`}>{post.title}</Link>;
};
const Footer = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    handleSearch(e.target.value);
  };

  const handleSearch = async (query) => {
    try {
      const results = await sanityClient().queryCollection("post", {
        include: "{..., author->, categories[]->{title}}",
        match: `(title match "${query}" || body match "${query}")`,
      });

      setSearchResults(results);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="row mt-2">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
              <div className="row">
                <div className="col-sm-6 col-6 mb-2">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/programs">Our Programs</Link>
                    </li>

                    <li>
                      <Link to="/engagewithus">Engage with us</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog and News</Link>
                    </li>
                    <li>
                      <Link to="/members">Our Members</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-6 mb-2">
                  <ul>
                    <li>
                      <Link to="/career">Career </Link>
                    </li>
                    <li>
                      <Link to="/career">Surveys</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/annual_report">Annual Report</Link>
                    </li>
                    <li>
                      <Link to="/privacy_policy">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2 ">
              <div className="row">
                <div className="col-md-4 col-12"></div>
                <div className="col-md-8 col-12">
                  <div className="right_al">
                    <div className="lat_foot mb-2">
                      <Link to="/donate" className="btn btn-danger">
                        Donate
                      </Link>
                      <form className="search_box">
                            <input
                              type="text"
                              className="form-control ml-2"
                              placeholder="Search"
                              onChange={handleSearchChange}
                              value={searchQuery}
                            />
                          </form>
                      <div className="overlay">
                      <div className="overlay-content">
                        <div>
    
                          <div
                            style={{ color: "white" }}
                            className="mt-2 w-full"
                          >
                            {searchResults &&
                              searchResults.map((post) => (
                                <SearchResult
                                  key={post._id}
                                  post={{ ...post }}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="social_link ">
                      <Link to={{pathname: "https://www.facebook.com/SSwomencoalition"}} target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </Link>
                      <Link to={{pathname: "https://www.instagram.com/sswconline/"}} target="_blank">
                        <i className="bx bxl-instagram"></i>
                      </Link>
                      <Link to={{ pathname: "https://twitter.com/sswcponline"}} target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </Link>
                      <Link to="/">
                        <i className="bx bxl-whatsapp"></i>
                      </Link>
                      <Link to={{ pathname: "https://www.linkedin.com/company/south-sudan-women-s-coalition"}} target="_blank">
                        <i className="bx bxl-linkedin"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="last_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 mx-auto">
              <img
                src={`${path}/image/fotlogo.png`}
                alt=""
                className="fot_logo"
              />
            </div>
            <div className="col-md-6 col-12 mx-auto lat_foot ">
              <p className="">
                © 2024 South Sudan Women's Coalition for Peace. <br /> All
                rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
