import React from 'react';
import { Link } from 'react-router-dom';

const WhatCompo = () => {
  return (
    <>
      <div className="whatcompo">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 col-12">
              <h1>Stronger Woman</h1>
              <p>
                As part of your women in peace project; we’ve compiled a list of
                questionnaires for you to answer. This will help us understand
                the challenges women face in South Sudan and pave the way for a
                lasting solution.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="new_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 mb-2 order-lg-1 order-md-1 order-2">
              <img src="./image/Group 56631.png" alt="" className="prog_img" />
            </div>
            <div className="col-lg-6 col-md-6 col-12 mb-2 order-lg-2 order-md-2 order-1">
              <h2 className="text-custom fn_40">Stronger nation program</h2>
              <p>
                As part of your women in peace project; we’ve compiled a list of
                questionnaires for you to answer. This will help us understand
                the challenges women face in South Sudan and pave the way for a
                lasting solution.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="whatcompo">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 col-12">
              <h1 className="">Graduate Support</h1>
              <p>
                As part of your women in peace project; we’ve compiled a list of
                questionnaires for you to answer. This will help us understand
                the challenges women face in South Sudan and pave the way for a
                lasting solution.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="new_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-12 mx-auto">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
                  <img
                    src="./image/icon1.png"
                    alt=""
                    className="what_com_img"
                  />
                </div>
                <div className="col-lg-8 col-md-6 col-12 mx-auto mb-2">
                  <h1>Community Advocacy</h1>
                  <p>
                    As part of your women in peace project; we’ve compiled a
                    list of questionnaires for you to answer. This will help us
                    understand the challenges women face in South Sudan and pave
                    the way for a lasting solution.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
                  <img
                    src="./image/icon1.png"
                    alt=""
                    className="what_com_img"
                  />
                </div>
                <div className="col-lg-8 col-md-6 col-12 mx-auto mb-2">
                  <h1>Men’s Engagement</h1>
                  <p>
                    As part of your women in peace project; we’ve compiled a
                    list of questionnaires for you to answer. This will help us
                    understand the challenges women face in South Sudan and pave
                    the way for a lasting solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new_sec what_com_about">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-8 col-12">
              <h1>Annual Report</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="new_sec">
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12  mb-2">
              <div className="card">
                <img
                  src="./image/tree-planting.png"
                  alt=""
                  className="what_we_do_img"
                />
                <div className="up_card_text">
                  <h4>Marathon Day</h4>
                  <Link to="/">
                    <i
                      class="fa fa-arrow-circle-o-right"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6  col-12  mb-2">
              <div className="card">
                <img
                  src="./image/tree1-planting.png"
                  alt=""
                  className="what_we_do_img"
                />
                <div className="up_card_text">
                  <h4>Women in society</h4>
                  <Link to="/">
                    <i
                      class="fa fa-arrow-circle-o-right"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatCompo;
