import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import emailjs from '@emailjs/browser';

export const DownloadReportForm = ({ reportDescription, reportUrl }) => {
  const [name, setName] = useState('');
  const [organization, setOrganization] = useState('');
  const [phone, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const errorToast = (message) => toast.error(message);
  const successToast = (message) => toast.success(message);

  const form = useRef();

  useEffect(() => {
    const storedEmail = localStorage.getItem('email') || '';
    if (!storedEmail) {
      setEmailExists(false);
    } else
    setEmail(storedEmail);
  }, [email]);

  function resetFormValues() {
    setName('');
    setOrganization('');
    setNumber('');
    setEmail('');
    setMessage('');
  }

  async function submitToAPI(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_138ftjc',
        'template_e4xpqhs',
        form.current,
        'JbC6yETtMNI4lRCiL'
      )
      .then(
        (result) => {
          setIsLoading(false);
          resetFormValues();
          successToast('Form Submitted!');
        },
        (error) => {
          console.log(error.text);
          errorToast('Something went wrong, try again please!');
          setIsLoading(false);
        }
      );
  }
  return (
    <>
      
        <div>
          <h4 style={{ color: '#333' }}>{reportDescription}</h4>
          {/*<p>Save report on your local drive.</p> */}
          <a className="btn btn-sm btn-primary" href={reportUrl} download>
            Download Report
          </a>
        </div>
      <Toaster position="bottom-center" />
    </>
  );
};
