import humanizeDuration from 'humanize-duration';
import moment from 'moment-timezone';
import imageUrlBuilder from '@sanity/image-url';
import { app } from '../config';

export const calculateReadTime = (content) => {
  const wordsPerMinute = 150; // Average reading speed in words per minute
  const words = content.split(' ').length; // Split content into words and count
  const readTime = Math.ceil(words / wordsPerMinute); // Calculate read time in minutes

  const formatReadTimeLabel = (time) => {
    if (time < 1) {
      return 'few seconds';
    } else if (time === 1) {
      return '1 minute';
    } else if (time < 60) {
      return `${time} minutes`;
    } else if (time === 60) {
      return '1 hour';
    } else {
      return humanizeDuration(time * 60 * 1000, {
        units: ['m'],
        round: true,
      });
    }
  };
  return formatReadTimeLabel(readTime) + ' read';
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const dateFormat = (date, format) => {
  return moment(date).format(format);
};

export const urlFor = (source) => {
  const builder = imageUrlBuilder({
    projectId: app.sanityProjectId,
    dataset: 'production',
  });
  return builder.image(source);
};

export const getFileUrl = (ref) => {

  console.log("ref", ref);
  const [ file, id, extension ] = ref.split('-');
  
  const url = `https://cdn.sanity.io/files/${app.sanityProjectId}/production/${id}.${extension}`;

  return { url, extension , file };
};
