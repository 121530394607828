import React from 'react';
import './WhatWeDo.css';
import WhatCompo from '../../components/what/WhatCompo';
import { useEffect } from 'react';
const WhatWeDo = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <WhatCompo />
    </>
  );
};

export default WhatWeDo;
