import { dateFormat } from "../../helper";

const EventOverview = ({ event }) => {
  return (
    <div class="col-12">
      <div
        className="card event-card"
        key={event.id}
        style={{ margin: "0 auto" }}
      >
        <div className="event-card-date">
          {dateFormat(event.start_date, "D, MMMM, YYYY")}
        </div>
        <div className="p-2 event-card-details ">
          <h2 style={{ color: "#076340" }}>{event.name}</h2>
          <p className="text-wrap block">{event.description}</p>
        </div>
      </div>
    </div>
  );
};

export default EventOverview;
