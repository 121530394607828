import { useEffect, useState } from "react";
import sanityClient from "../../lib/sanity";
import toast, { Toaster } from "react-hot-toast";

export const IndividualsTable = () => {
  const [individuals, setIndividuals] = useState([]);
  const [individualsLoading, setIndividualsLoading] = useState(false);
  const errorToast = (message) => toast.error(message);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20; // Number of items per page

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = individuals.slice(startIndex, endIndex);

  function handleNext() {
    setCurrentPage((prevPage) => prevPage + 1);
  }

  function handlePrevious() {
    setCurrentPage((prevPage) => prevPage - 1);
  }

  useEffect(() => {
    const fetchIndividuals = async () => {
      try {
        const data = await sanityClient().queryCollection("individuals", {
          sort: "index"
        });
        setIndividualsLoading(true);
        setIndividuals(data.sort((a, b) => a.index - b.index));
      } catch (err) {
        console.log("latest post fetch error", err);
        errorToast(err.message);
      } finally {
        setIndividualsLoading(false);
      }
    };

    fetchIndividuals();
  }, []);

  if(individualsLoading) {
    return <p>Loading....</p>
  }

  return (
    <>
      <div class="table-responsive">
        <table class="table table-responsive table-striped table-bordered">
          <thead style={{ background: "#e5fbe5" }}>
            <tr>
              <td>Number</td>
              <th>Individual Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.length === 0 ? (
            <div>
              <p>No individual members available</p>
            </div>
          ) : paginatedData.map((v, ind) => {
              return (
                <>
                  <tr key={v.email}>
                    <td>{v.index}</td>
                    <td>{v.name}</td>
                    <td>{v.email}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div class="flex justify-content-center">
        <ul class="pagination flex justify-content-between">
          <li class="page-item">
            <button class="btn btn-primary" onClick={handlePrevious}>
              Previous
            </button>
          </li>
          <li class="page-item">
            <button class="btn btn-primary" onClick={handleNext}>
              Next
            </button>
          </li>
        </ul>
      </div>
      <Toaster position="bottom-center" />
    </>
  );
};
