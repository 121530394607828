import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Subscribe from "../../components/calltoaction/Subscribe";
import { Link } from "react-router-dom";
import sanityClient from "../../lib/sanity";
import { toast } from "react-hot-toast";
import BlogBody from "../../components/blog/BlogBody";
import { dateFormat, urlFor } from "../../helper";

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  const [error, setError] = useState(false);
  const errorToast = (message) => toast.error(message);

  useEffect(() => {
    const latestPost = async () => {
      try {
        const data = await sanityClient().queryDocument("post", slug, {
          include: "{..., author->, categories[]->{title}}",
          sort: {},
        });
        setPostLoading(true);
        setPost(data[0]);
      } catch (err) {
        console.log("latest post fetch error", err);
        setError(true);
        errorToast(err.message);
      } finally {
        setPostLoading(false);
      }
    };

    latestPost();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [slug]);

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Error loading log post!</h3>
        </div>
      </div>
    );
  }

  if (Object.keys(post).length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Blog post doesn't exist!</h3>
        </div>
      </div>
    );
  }

  const imageUrl = urlFor(post?.mainImage).url();
  const authorImageUrl = urlFor(post?.author?.image?.asset._ref).url();

  return (
    <>
      {postLoading ? (
        <div className="new_sec blog_100_sec" style={{ height: "500px" }}>Fetching Post....</div>
      ) : (
        <div className="new_sec blog_100_sec">
          <div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6">
                  <h1>{post.title}</h1>
                  <p>{post.description}</p>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <p
                      className="text-secondary"
                      style={{ width: "fit-content" }}
                    >
                      {dateFormat(post._createdAt, "DD, MMMM, YYYY")}
                    </p>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <div
                      className="mr-2"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <img
                        src={authorImageUrl}
                        className="img-fluid rounded-circle"
                        alt={post.author.name}
                      />
                    </div>
                    <p>{post.author.name}</p>
                  </div>
                </div>
                <div className="col-6">
                  <img
                    src={imageUrl}
                    alt={post?.title}
                    className="sm_blog_img"
                    style={{ objectFit: "cover", height: "100%", aspectRatio: "4/3" }}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <BlogBody body={post.body} />
            </div>
          </div>
        </div>
      )}
      <Subscribe />
      <div
        className="image_div1"
        style={{
          background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/blog/help+make+a+change.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-6  col-md-8 col-12 mx-auto d-flex justify-content-center  flex-column">
              <h1 className="title_text mb-2 text-light">
                Help make a change!
              </h1>

              <div>
                <Link to="/donate" className="btn btn-primary">
                  BECOME A SPONSOR
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-12 mx-auto"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
