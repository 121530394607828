import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { useHistory } from "react-router-dom";
import sanityClient from "../../lib/sanity";
import toast, { Toaster } from "react-hot-toast";
import { CareersList } from "../../components/CareersList";

const Career = () => {
  const [careers, setCareers] = useState([]);
  const [careersLoading, setCareersLoading] = useState(false);
  const errorToast = (message) => toast.error(message);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const his = useHistory();

  useEffect(() => {
    const fetchCareers = async () => {
      try {
        const data = await sanityClient().queryCollection("career");
        setCareersLoading(true);
        setCareers(data);
      } catch (err) {
        console.log("latest post fetch error", err);
        errorToast(err.message);
      } finally {
        setCareersLoading(false);
      }
    };

    fetchCareers();
  }, []);

  console.log(careers);
  return (
    <>
      <div className="pop_up_box">
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-lg-10 col-md-11 col-12 mx-auto">
              <div
                className="pop_box_hero"
                style={{
                  background: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/career/sswcp+career.webp')`,
                  backgroundSize: "cover",
                  backgroundPosition: "left",
                }}
              >
                <div className="icon_arrow">
                  <img
                    src="./image/back.png"
                    onClick={() => {
                      his.goBack();
                    }}
                    className="cur"
                    width="50px"
                    alt=""
                  />
                </div>
                <div className="center_box center_boxx">
                  <div className="pl-5 pr-5">
                    <h1 className="about_text text-light p-2">
                      Become part of us
                    </h1>
                    <p className="text-light p-2">
                      Are you looking to create a positive impact in South
                      Sudan? Join us and be one of the peace makers.
                    </p>
                    <div className="p-2">
                      <Link to="/" className="btn btn-primary">
                        Available Opportunities
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="new_sec bg_light">
          <div className="container  ">
            <div className="row">
              <div className="col-lg-8 col-md-10 col-12 mx-auto">
                <p className="text-center text-custom fn_20 pt-5 pb-5">
                  We are a women lead organization working for peace and women
                  empowerment in the country. We value diversity, team work and
                  we ensure your talent is further amplified. With us it's not
                  about a career but journey to becoming a whole new person with
                  wider skill set, knowledge and leadership. Become part of us!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <h2 className="text-center">Current Openings</h2>
          <div className="row mt-5">
            <div className="col-12">
              {
                careersLoading ? <p>Loading Careers</p> : <CareersList careers={careers} />
              }
            </div>
          </div>
        </div>

        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
              <div className="card">
                <img
                  src="./image/teamwork.png"
                  alt=""
                  className="team_work_img "
                />
                <div className="p-3 text-center">
                  <h4>TEAM WORK</h4>
                  <p>
                    We believe in the power of team work. When different hands
                    come together, greater things are accomplished.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
              <div className="card">
                <img
                  src="./image/leadership.png"
                  alt=""
                  className="team_work_img"
                />
                <div className="p-3 text-center">
                  <h4>ENHANCEMENT</h4>
                  <p>
                    We learn from each other and empowerment of your skill set
                    if of great importance to us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
              <div className="card">
                <img
                  src="./image/incentive.png"
                  alt=""
                  className="team_work_img"
                />
                <div className="p-3 text-center">
                  <h4>INCENTIVES</h4>
                  <p>
                    We offer a peaceful and vibrant work environment, and a
                    place to earn while impacting others.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-12  mb-2">
              <Link to="/about" className="card_link">
                <div className="card">
                  <img
                    src="https://s3.amazonaws.com/sswcp.org/image/inner/career/about+us.webp"
                    alt=""
                    className="annul_img"
                  />
                  <div className="upp_card_text">
                    <h4>ABOUT US</h4>

                    <Link to="/about" className="link_flex">
                      <span className="fn_14">Learn More</span>
                      <i
                        class="bx bx-right-arrow-circle"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6  col-12  mb-2">
              <Link to="/programs" className="card_link">
                <div className="card">
                  <img
                    src="https://s3.amazonaws.com/sswcp.org/image/inner/career/our+programs.webp"
                    alt=""
                    className="annul_img"
                  />
                  <div className="upp_card_text">
                    <h4>OUR PROGRAMS</h4>

                    <Link to="/programs" className="link_flex">
                      <span className="fn_14">Learn More</span>
                      <i
                        class="bx bx-right-arrow-circle"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
        <Toaster />
      </div>
    </>
  );
};

export default Career;
