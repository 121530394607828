import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './ModalD.css';
const ModalD = () => {
  const [modalshow, setModalShow] = useState(false);

  useEffect(() => {
    // setNum(Math.floor(Math.random() * 3) + 1);
    let pop_up = localStorage.getItem('pop_up');
    if (pop_up < 1) {
      setTimeout(() => {
        setModalShow(true);
      }, 5000);
    }
  }, []);
  window.addEventListener('beforeunload', (event) => {
    localStorage.removeItem('pop_up');
  });
  const closebtn = () => {
    let pop_up = localStorage.getItem('pop_up');
    // console.log(pop_up);
    if (pop_up) {
      localStorage.setItem('pop_up', Number(pop_up) + 1);
    } else {
      localStorage.setItem('pop_up', 1);
    }
    setModalShow(false);
  };
  return (
    <>
      {modalshow && (
        <>
          <div className="modal_box">
            <div className="modal_inn_box">
              <div className="p-3">
                <h4 className=" text-center mt-3 ">
                  SUBSCRIBE AND STAY CONNECTED WITH US
                </h4>
                <p className="text-center   fn_14">
                  Get updates from us and be inspired about the work we do.
                </p>
              </div>
              <div className="modl">
                <div className="input-group mt-3 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Address"
                    required
                  />
                  <div className="input-group-append">
                    <button type="button" className="input-group-text btn">
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
              <div className="cross_box" onClick={() => closebtn()}>
                x
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ModalD;
