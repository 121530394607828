import React from 'react';
import './Home.css';
import Hero from '../../components/home/Hero';
import AfterHero from '../../components/home/AfterHero';
import { useEffect } from 'react';
import DonationModal from '../../components/donateModal/donateModal';
const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <DonationModal/>
      <Hero/>
      <AfterHero/>
    </>
  );
};

export default Home;
