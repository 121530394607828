import React from 'react';
import { Link } from 'react-router-dom';
import PartnerSlider from '../PartnerSlider';
import { useState } from 'react';
import AboutMore from './AboutMore';

const HeroAbout = () => {
  const [showPop, setShowPop] = useState(false);

  return (
    <>
      {!showPop && (
        <>
          <div className='heroabout'>
            <div className=' upp_box'>
              <h1 className='about_text text-center mb-2 text-light'>
                SOUTH SUDAN WOMEN'S
                COALITION FOR PEACE
              </h1>
              <div className='upp_inn_box'>
                <p>
                  The South Sudan Women Coalition for Peace is a coalition of
                  over 50 women and women-led organizations from South Sudan and
                  the regional countries of Kenya and Uganda. Formed in
                  September 2017, the coalition brings together South Sudanese
                  women irrespective of their political affiliation, religious,
                  socioeconomic status, and issue-related divides including
                  those from refugee camps and non-government-controlled areas.
                  The overall objective of the Coalition is to build a strong
                  Women’s Movement in South Sudan, which will be able to address
                  a wide range of issues under women, peace and security
                  undermining their effective engagement on matters affecting
                  their lives, including insecurity, underrepresentation in
                  leadership and decision-making structures, national
                  development and peace building.
                </p>
                <button
                  className='btn btn-primary_black'
                  onClick={() => {
                    setShowPop(true);

                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                >
                  Read more &nbsp;
                  <i className='bx bx-right-arrow-circle'></i>
                </button>
              </div>
            </div>
          </div>
          <div className='up_div'>
            <div className='about_up_box'>
              <div className='container mt-3'>
                <div className='row'>
                  <div className='col-lg-12 col-12 mx-auto'>
                    <div className=' mb-2'>
                      <button className='btn btn-light'>OUR VISION</button>
                    </div>
                    <p className='text-light'>
                      The South Sudan Women’s Coalition envisions a peaceful
                      South Sudan where women enjoy their full rights and
                      contribute to the development of the country
                    </p>
                  </div>
                </div>
              </div>

              <div className='container mt-3'>
                <div className='row'>
                  <div className='col-lg-12 col-12 mx-auto'>
                    <div className=' mb-2'>
                      <button className='btn btn-light'>OUR MISSION</button>
                    </div>
                    <p className='text-light' style={{textAlign:"justify"}}>
                      The Coalition strives to build a strong Women’s Movement
                      in South Sudan, to address women’s issues under the women,
                      peace and security agenda, undermining their effective
                      engagement on matters affecting their lives, including
                      insecurity, underrepresentation in leadership and
                      decision-making structures, national development and peace
                      building
                    </p>
                    <div className='mt-5'>
                      <button className='btn btn-light'>OUR VALUES</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container mt-5'>
                <div className='row'>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/1.png' alt='' />
                      <h5 className='fn_16'>Non-Violence</h5>
                      <p className='fn_12'>
                        We choose peace over violence to achieve a society that
                        all enjoy their rights
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/4.png' alt='' />
                      <h5 className='fn_16'>Transparency</h5>
                      <p className='fn_12'>
                        We communicate openly and honestly about our management
                        and operational procedures
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/2.png' alt='' />
                      <h5 className='fn_16'>Accountability</h5>
                      <p className='fn_12'>
                        We hold ourselves and others to highest standard of
                        accountability
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/3.png' alt='' />
                      <h5 className='fn_16'>Truth-Worthy</h5>
                      <p className='fn_12'>
                        We are open and choose to provide clarity in all our
                        work
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/5.png' alt='' />
                      <h5 className='fn_16'>Service Committed</h5>
                      <p className='fn_12'>
                        We recognize women’s contribution to achieving a
                        peaceful society
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/6.png' alt='' />
                      <h5 className='fn_16'>Equity & Equality</h5>
                      <p className='fn_12'>
                        Fair treatment and equal opportunity for women and girls
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/8.png' alt='' />
                      <h5 className='fn_16'>Non-Discriminatory</h5>
                      <p className='fn_12' style={{textAlign:"justify"}}>
                        We value our diversity (Age, Religious Affiliation,
                        Education, Marital Status, Sex, Diversity, Ethnicity).
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4 col-sm-6 col-12 mb-4'>
                    <div className='cardd text-light '>
                      <img src='./image/iconn/7.png' alt='' />
                      <h5 className='fn_16'>Inclusivity</h5>
                      <p className='fn_12'>
                        Women urgency in all matters that affects their lives
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='new_sec bg_light'>
            <div>
              <div className='container'>
                <button className='btn btn-primary'>Our Team</button>
                <h3 className=' small_text mt-3 mb-2'>
                  Meet the team behind the milestone we’ve achieved and the
                  drivers of the organization.
                </h3>
              </div>
              <div className='card-deck px-4'>
                  <div className='card'>
                    <img
                      src='./image/Rita M. Lopidia.jpg'
                      alt=''
                      className='team_img'
                      style={{boxShadow:"rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"}}
                    />
                    <div className='team_card_text'>
                      <h4 className='fn_12'>Rita M. Lopidia</h4>
                    </div>
                  </div>
               
                  <div className='card'>
                    <img
                      src='./image/Dr. Koiti Emmil.jpg'
                      alt=''
                      className='team_img'
                      style={{boxShadow:"rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"}}
                    />
                    <div className='team_card_text'>
                      <h4 className='fn_12'>Dr. Koiti Emmily Nasungba Modi</h4>
                    </div>
                  </div>
                  <div className='card'>
                    <img
                      src='./image/angelina.jpg'
                      alt=''
                      className='team_img'
                      style={{boxShadow:"rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"}}
                    />
                    <div className='team_card_text'>
                      <h4 className='fn_12'>Dr. Angelina Mattijo Bazugba</h4>
                    </div>
                  </div>
                  <div className='card'>
                    <img
                      src='./image/Mrs. Zeinab.png'
                      alt=''
                      className='team_img'
                      style={{boxShadow:"rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"}}
                    />
                    <div className='team_card_text'>
                      <h4 className='fn_12'>Mrs. Zeinab Yassin Hagelsafi</h4>
                    </div>
                  </div>
                  <div className='card'>
                    <img
                      src='./image/Professor Pauline Elaine Riak.jpg'
                      alt=''
                      className='team_img'
                      style={{boxShadow:"rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"}}
                    />
                    <div className='team_card_text'>
                      <h4 className='fn_12'>Professor Pauline Elaine Riak</h4>
                    </div>
                </div>
                {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12  mb-2" style={{paddingLeft: 0, paddingRight: 0}}>
                  <div className="card">
                    <img src="./image/team/4.png" alt="" className="team_img" />
                    <div className="team_card_text">
                      <h4 className="fn_12">
                        Finance Officer <br /> Onzia John James
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12  mb-2" style={{paddingLeft: 0, paddingRight: 0}}>
                  <div className="card">
                    <img src="./image/team/5.png" alt="" className="team_img" />
                    <div className="team_card_text">
                      <h4 className="fn_12">
                        Logistic & Procurement Officer <br /> Peter Sebit Alber
                        Laku
                      </h4>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <Link to='/members'>
            <div
              className='image_div1 about_left'
              style={{
                background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/outer/about+us/coalition+members.webp')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {/* <div className="container pt-5">
                 <div className="row">
                  <div className="col-lg-8  col-md-10 col-12 mx-auto d-flex justify-content-center  flex-column">
                    <h1 className="title_text mb-2 text-light">
                      Coalition Members  <i
                          className="bx bx-right-arrow-circle text-light"
                          aria-hidden="true"
                        ></i>
                      <Link to="/">
                        <i
                          className="bx bx-right-arrow-circle text-light"
                          aria-hidden="true"
                        ></i>
                      </Link>{' '}
                    </h1>
                  </div>
                  <div className="col-lg-4 col-md-2 col-12 mx-auto"></div>
                </div> 
              </div> */}
              <div className='inbox'>
                <h1 className='text-light p-3'>
                  Coalition Members
                  <i
                    className='bx bx-right-arrow-circle text-light'
                    aria-hidden='true'
                  ></i>
                </h1>
              </div>
            </div>
          </Link>

          <div className='new_sec'>
            <div className='text-center'>
              <button className='btn btn-dark'>OUR PARTNERS</button>
            </div>
            <div className='container-fluid mt-5 mb-2' style={{width: "90%"}}>
              <PartnerSlider />
            </div>
            <div className='container mt-5'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-12  mb-2'>
                  <Link to='/programs' className='card_link'>
                    <div className='card'>
                      <img
                        src='https://s3.amazonaws.com/sswcp.org/image/outer/about+us/sswcp+our+program.webp'
                        alt=''
                        className='what_we_do_img ex_h'
                      />
                      <div className='up_card_text up_card_textt'>
                        <div to='/programs' className='link_flex'>
                          <h4>OUR PROGRAMS</h4>
                          {/* <span className='fn_14'>Learn More</span> */}
                          <i
                            className='bx bx-right-arrow-circle'
                            aria-hidden='true'
                            style={{ marginTop: '3px' }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='col-lg-6 col-md-6  col-12  mb-2'>
                  <Link to='/blog' className='card_link'>
                    <div className='card'>
                      <img
                        src='https://s3.amazonaws.com/sswcp.org/image/outer/about+us/what+we+do.webp'
                        alt=''
                        className='what_we_do_img ex_h'
                      />
                      <div className='up_card_text up_card_textt'>
                        <div to='/blog' className='link_flex'>
                          <h4>WHAT WE DO</h4>

                          {/* <span className='fn_14'>Learn More</span> */}
                          <i
                            className='bx bx-right-arrow-circle'
                            aria-hidden='true'
                            style={{ marginTop: '3px' }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Link to='/blog'>
            <div
              className='image_div1'
              style={{
                background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/outer/about+us/blog+and+news.webp')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div style={{ width: "100%",display: 'flex', alignItems: 'center', marginLeft: "5%" }}>
                <h1
                  className='title_text mb-2 text-light fn_50'
                  style={{ display: 'flex' }}
                >
                  News and Blogs
                  <i
                    className='bx bx-right-arrow-circle'
                    aria-hidden='true'
                    style={{ marginTop: '7px', marginLeft: "5px" }}
                  ></i>
                </h1>
              </div>
            </div>
          </Link>
        </>
      )}

      {showPop && (
        <AboutMore onClick={() => setShowPop} />
      )}
    </>
  );
};

export default HeroAbout;
