import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../lib/sanity";
import HeroSlider, { Slide } from "hero-slider";
import { urlFor } from "../../helper";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Hero = () => {
  const [heroImages, setHeroImages] = useState([]);
  useEffect(() => {
    const fetchHeroImages = async () => {
      try {
        const data = await sanityClient().queryCollection("heroSlide");
        setHeroImages(data);
      } catch (err) {
        console.log("latest post fetch error", err);
      }
    };
    fetchHeroImages();
  }, []);

  return (
    <>
      <HeroSlider
        height="100vh"
        autoplay
        controller={{
          initialSlide: 1,
          slidingDuration: 500,
          slidingDelay: 100,
          onSliding: (nextSlide) =>
            console.debug("onSliding(nextSlide): ", nextSlide),
          onBeforeSliding: (previousSlide, nextSlide) =>
            console.debug(
              "onBeforeSliding(previousSlide, nextSlide): ",
              previousSlide,
              nextSlide
            ),
          onAfterSliding: (nextSlide) =>
            console.debug("onAfterSliding(nextSlide): ", nextSlide),
        }}
      >
        {heroImages &&
          heroImages.map((hero, index) => {
            console.log(hero);
            return (
              <>
                <Slide
                  key={index}
                  shouldRenderMask
                  background={{
                    backgroundImage: `url(${urlFor(hero.mainImage).url()})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div
                    className="h-full py-4"
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      width: "80%",
                      margin: "0 auto",
                      color: "#fff",
                    }}
                  >
                    <div className="text-center">
                      <h1 className="h1">{hero.title}</h1>
                      {hero.relative === "internal" ? (
                        <Link
                          to={{ pathname: hero.redirectUrl }}
                          className="btn btn-danger"
                        >
                          Learn more &nbsp;{" "}
                          <i class="bx bx-right-arrow-circle"></i>
                        </Link>
                      ) : (
                        <a
                          className="btn btn-danger"
                          href={hero.redirectUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more &nbsp;{" "}
                          <i class="bx bx-right-arrow-circle"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </Slide>
              </>
            );
          })}
      
      </HeroSlider>
    </>
  );
};

export default Hero;
