import React from 'react';
import { Link } from 'react-router-dom';
import Subscribe from '../calltoaction/Subscribe';
import HomeBlogPosts from '../blog/HomeBlogPosts';
import { TestimonialCard } from './Testimonials';
import Slider from 'react-slick';

const testimonials = [
  {
    id: 1,
    name: 'Elizabeth Ajok',
    title: 'Member of SSWCP',
    message:
      'Am glad to be part of such powerful women umbrella that empowers and enabled me to interact with women and women network beyond the Northern border of Sudan and South Sudan!',
    avatar: 'https://s3.amazonaws.com/sswcp.org/image/Elizabeth+Ajok.webp',
  },
  {
    id: 2,
    name: 'Angela Leke',
    title: 'Member of SSWCP',
    message:
      'I have seen the women in our villages work tirelessly just to make ends meet. I always wanted to lend them a helping hand, to uplift the less fortunate. They are the unsung heroes, toiling day and night for the sake of their families.',
    avatar: 'https://s3.amazonaws.com/sswcp.org/image/Angela+Leke.webp',
  },
];

var settings = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplay: true,
  speed: 500,
  slidesToShow: 1, // dynamic!
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      }, 
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    }
  ],
};

const AfterHero = () => {
  return (
    <>
      <div className="box-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-12 mx-auto">
              <div className="low_border">
                <h1 className="title_text">
                  {' '}
                  South Sudan Women's Coalition for Peace - SSWCP{' '}
                </h1>
                <div className="low_border_inn"></div>
              </div>
              <div className="row">
                <div
                  className="col-lg-7 col-md-6 col-12 mx-auto"
                  style={{ margin: 'top' }}
                >
                  <p
                    className=" normal_font"
                    style={{ textAlign: 'left', margin: 'auto' }}
                  >
                    The South Sudan Women Coalition for Peace is a coalition of
                    over 50 women and women-led organizations from South Sudan
                    and the regional countries of Kenya and Uganda. Formed in
                    September 2017, the coalition brings together South Sudanese
                    women irrespective of their political affiliation,
                    religious, socioeconomic status, and issue-related divides
                    including those from refugee camps and
                    non-government-controlled areas.
                  </p>
                  <Link
                    to="/about"
                    className="btn btn-primary"
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  >
                    Learn more &nbsp;
                    <i class="bx bx-right-arrow-circle animated infinite headShake"></i>
                    {/* <i class='bx bx-right-arrow-circle'></i> */}
                  </Link>
                </div>
                <div className="col-lg-5 col-md-6 col-12 mx-auto mb-2">
                  <img
                    src="https://s3.amazonaws.com/sswcp.org/image/outer/about+us/about+us+sswcp.webp"
                    alt=""
                    className="about_us_home_img"
                    style={{
                      boxShadow:
                        'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                      borderRadius: '0.5rem',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new_sec">
        <div className="container">
          <h1 className="title_text text-center mb-4 text_unde">What we do?</h1>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12  mb-2 ">
              <Link to="/programs" class="card_link">
                <div className="card">
                  <img
                    src="https://s3.amazonaws.com/sswcp.org/image/outer/home/our+program.webp"
                    alt=""
                    className="what_we_do_img"
                  />
                  <div className="up_card_text home-card-cta">
                    <h4>Our Programs</h4>
                    <Link to="/programs">
                      <i class="bx bx-right-arrow-circle"></i>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12  mb-2">
              <Link to="/blog" class="card_link">
                <div className="card">
                  <img
                    src="https://s3.amazonaws.com/sswcp.org/image/outer/home/our+activities.webp"
                    alt=""
                    className="what_we_do_img"
                  />
                  <div className="up_card_text home-card-cta">
                    <h4>Our Activities</h4>
                    <Link to="/blog">
                      <i class="bx bx-right-arrow-circle"></i>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12  mb-2">
              <Link to="/annual_report" class="card_link">
                <div className="card">
                  <img
                    src="https://s3.amazonaws.com/sswcp.org/image/outer/home/our+report.webp"
                    alt=""
                    className="what_we_do_img"
                  />
                  <div className="up_card_text home-card-cta">
                    <h4>Our Reports</h4>
                    <Link to="/annual_report">
                      <i class="bx bx-right-arrow-circle"></i>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Link to="/engagewithus">
        <div
          className="image_div"
          style={{
            background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/outer/home/member+sign+up.webp')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            boxShadow:
              'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px',
          }}
        >
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-6  col-md-10 col-12 mx-auto d-flex justify-content-center  flex-column">
                <h1 className="title_text mb-2 text-light">
                  How to become a member
                </h1>
                <p
                  className="mt-3  text-light"
                  style={{ textAlign: 'justify' }}
                >
                  South Sudan Women's Coalition for peace (SSWCP) is founded
                  with the goal of empowering Women in South Sudan. Connect with
                  us to cover range of issues under women, peace and security.
                  With effective engagement on matters of national peace
                  building.
                </p>

                <Link to="/engagewithus" className="text-light engage-cta">
                  <p style={{ fontSize: '1.6rem' }}>Member Sign up</p>
                  <img
                    src="./image/arrow.png"
                    alt=""
                    width="35px"
                    style={{ marginBottom: '10px' }}
                  />
                </Link>
              </div>
              <div className="col-lg-4 col-md-2 col-12 mx-auto"></div>
            </div>
          </div>
        </div>
      </Link>
      <div className="new_sec" style={{ borderBottom: '5px #707170 solid' }}>
        <div className="container">
          <h1 className="title_text text-center mb-2 ">OUR PRESENCE</h1>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="flag_div">
                <img
                  src="https://s3.amazonaws.com/sswcp.org/image/iconn/South+Sudan.webp"
                  alt=""
                  className="flag_img"
                />
                <h3 className=" fb_700 text-center p-2">South Sudan</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="flag_div">
                <img
                  src="./image/Untitled design (8).png"
                  alt=""
                  className="flag_img"
                />
                <h3 className=" fb_700 text-center p-2">Uganda</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
              <div className="flag_div">
                <img
                  src="./image/Untitled design (7).png"
                  alt=""
                  className="flag_img"
                />
                <h3 className=" fb_700 text-center p-2">Kenya</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="new_sec">
        <div className="low_border mb-2">
          <h1 className="title_text  text-center mb-2">Members Testimonials</h1>
          <div
            className="low_border_inn"
            style={{ margin: '0 auto', position: 'static', width: '30%' }}
          ></div>
        </div>
        <div className="container">
          <Slider {...settings} className="row">
            {testimonials.map((testimonial) => {
              return <TestimonialCard testimonial={testimonial} />;
            })}
          </Slider>
        </div>
      </div>
      <HomeBlogPosts />
      <Subscribe />
      <Link to="/donate">
        <div
          className="image_div1"
          style={{
            background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/outer/home/sponsor+us.webp')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-6  col-md-8 col-12 mx-auto d-flex justify-content-center  flex-column">
                <h1 className="title_text mb-2 text-light">
                  You can help <br /> make a change
                </h1>
                <div>
                  <Link
                    to="/donate"
                    className="btn btn-primary pt-2 pb-2 pl-5 pr-5"
                  >
                    BECOME A SPONSOR
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 col-12 mx-auto"></div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default AfterHero;
