import { Switch, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import Error from "../pages/Error";
import About from "../pages/about/About";
import Blog from "../pages/blog/Blog";
import Programs from "../pages/programs/Programs";
import WhatWeDo from "../pages/whatwedo/WhatWeDo";
import Engage from "../pages/engagewithus/Engage";
import Privacy from "../pages/normalpage/Privacy";
import Contact from "../pages/normalpage/Contact";
import Annual from "../pages/normalpage/Annual";
import DonatePage from "../pages/normalpage/DonatePage";
import SearchPage from "../pages/normalpage/SearchPage";
import SecurePage from "../pages/normalpage/SecurePage";
import Career from "../pages/normalpage/Career";
import Members from "../pages/normalpage/Members";
import BlogPost from "../pages/blog/BlogPost";

const routes = [
  {
    id: 1,
    path: "/",
    component: Home,
  },
  {
    id: 2,
    path: "/about",
    component: About,
  },
  {
    id: 3,
    path: "/annual_report",
    component: Annual,
  },
  {
    id: 4,
    path: "/blog",
    component: Blog,
  },
  {
    id: 5,
    path: "/blog/:slug",
    component: BlogPost,
  },
  {
    id: 6,
    path: "/career",
    component: Career,
  },
  {
    id: 7,
    path: "/contact",
    component: Contact,
  },
  {
    id: 8,
    path: "/donate",
    component: DonatePage,
  },
  {
    id: 9,
    path: "/engagewithus",
    component: Engage,
  },
  {
    id: 10,
    path: "/members",
    component: Members,
  },
  {
    id: 11,
    path: "/privacy_policy",
    component: Privacy,
  },
  {
    id: 12,
    path: "/programs",
    component: Programs,
  },
  {
    id: 13,
    path: "/search",
    component: SearchPage,
  },
  {
    id: 14,
    path: "/secure",
    component: SecurePage,
  },
  {
    id: 15,
    path: "/whatwedo",
    component: WhatWeDo,
  },
];

const Routes = () => {
  return (
    <Switch>
      {routes.map(({ component, path }) => (
        <Route exact path={path} component={component} key={path} />
      ))}
      <Route component={Error} />
    </Switch>
  );
};

export default Routes;
