export const TestimonialCard = ({ testimonial }) => {
  return (
    <div className="col-12">
          <div className="card">
      <div className="card-body">
        <div>
          <div>
            <i class="bx bx-comment" aria-hidden="true"></i>
          </div>
          <p className="text-secondary">{testimonial.message} </p>
        </div>
        <div className="d-flex align-items-center mt-2">
          <figure className="mr-2">
            <img
              src={testimonial.avatar}
              alt={testimonial.name}
              className="testi_img img-fluid rounded-circle "
            />
          </figure>
          <div>
            <h5 className="h5">
              {testimonial.name}
              <br />
              {testimonial.title}
            </h5>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export const Testimonials = ({ testimonials }) => {
  console.log(testimonials.lengeth);
  return (
    <div className="card-group" style={{ background: 'red' }}>
      {testimonials.map((testimonial) => {
        return (
          <TestimonialCard testimonial={testimonial} key={testimonial.id} />
        );
      })}
    </div>
  );
};
