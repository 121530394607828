import React from 'react';
import Slider from 'react-slick';

const PartnerSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div className="part_div">
          <img src="https://s3.amazonaws.com/sswcp.org/image/outer/about+us/Berghof+Foundation+Logo.webp" alt="" className="part_img" />
        </div>
        <div className="part_div">
          <img src="https://s3.amazonaws.com/sswcp.org/image/outer/about+us/CORDAID+Logo.webp" alt="" className="part_img" />
        </div>
        <div className="part_div">
          <img src="https://s3.amazonaws.com/sswcp.org/image/outer/about+us/NPA+Logo.webp" alt="" className="part_img" />
        </div>
        <div className="part_div">
          <img src="https://s3.amazonaws.com/sswcp.org/image/outer/about+us/Swiss+Embassy+Logo.webp" alt="" className="part_img" />
        </div>
        <div className="part_div">
          <img src="https://s3.amazonaws.com/sswcp.org/image/outer/about+us/UN+Women+Logo.webp" alt="" className="part_img" />
        </div>
        <div className="part_div">
          <img src="https://s3.amazonaws.com/sswcp.org/image/outer/about+us/UNDP+Logo.webp" alt="" className="part_img" />
        </div>
      </Slider>
    </>
  );
};

export default PartnerSlider;
