import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../footer/Footer';
import sanityClient from '../../lib/sanity';
import { ListEvents } from './ListEvents';

const EngageComp = () => {
  const [showpop, setShowpop] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setEventLoading(true);
        const data = await sanityClient().queryCollection('event');
        setEventList(data);
      } catch (err) {
        console.log('fetching event list error', err);
      } finally {
        setEventLoading(false);
      }
    };

    fetchEvents();
  }, []);

  return (
    <>
      {!showpop && (
        <>
          <div className="engage flex justify-content-center align-items-end">
            <div className="p-4 ">
              
          <h1 style={{color:"white"}}>Engage for the future!</h1>
        </div>
              {/*
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="boxx">
                    <div>
                      <button className="btn btn-dark mb-2">The Isuue</button>
                    </div>
                    <h2 className="fb_700 ">
                      Creating a Secure South Sudan: Community <br /> Women
                      Speak
                    </h2>
                    <br />
                    <Link to="/career">
                      Read more about this &nbsp;
                      <i class="bx bx-right-arrow-alt"></i>{' '}
                    </Link>
                  </div>
                </div>
              </div>
              */}
            </div>
          
          <div className="new_sec eng_box">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-10 col-12 mx-auto mb-2">
                  <div className="card p-3" style={{backgroundColor: "#353535"}}>
                    <h3 className="text-light">How to become a member?</h3>
                    <div className="dd-flex">
                      <p className="text-light fn_14">
                        Are you looking to create a positive impact in South
                        Sudan? Apply with us and become one of the peace makers.
                      </p>
                      <div>
                        <button
                          className="btn btn-light" style={{borderRadius:"1rem"}}
                         /* onClick={() => {
                            setShowpop(true);

                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth',
                            });
                          }} */
                        > <Link to="/career" style={{color:"black"}}>
                          Read more 
                          <i class=" bx bx-right-arrow-circle"></i> </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-4 mb-2">
              <div className="row row_sur">
                <div className="col-lg-4 col-md-4  col-12 mx-auto ">
                  <img src="./image/survey.png" alt="" className="surimg" />
                </div>
                <div className="col-lg-8 col-md-8 col-12 mx-auto ">
                  <h1 className="">Survey</h1>
                  <p className="fn_14">
                    As part of our women in peace project; we’ve compiled a list
                    of questionnaires for you to answer.
                    This will help us understand the challenges women face in South Sudan and pave
                    the way for a lasting solution.
                  </p>
                  <div className="text-right">
                    <Link to={{ pathname: "https://sswcp.notion.site/Welcome-to-SSWCP-Surveys-d94c0111b5dc4fc6895e3829a8730d28?pvs=4" }} target="_blank" className="btn btn-primary">
                      Take Survey
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new_sec " style={{backgroundColor: "#353535"}} id="event">
            <h1 className="text-center  text-light mb-4">Event Calendar</h1>
            {eventLoading ? (
            <div>Event Loading....</div>
          ) : (
            <ListEvents events={eventList} />
          )}
            
          </div>
          <Link to="/contact">
            <div className="eng_contact">
              <h2 className="text-light about_text">Contact Us</h2>
            </div>
          </Link>
        </>
      )}

      {showpop && (
        <div className="pop_up_box">
          <div
            className="pop_box_hero"
            style={{
              background: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)), url('./image/inner/career/sswcp career.webp')`,
              backgroundSize: 'cover',
              backgroundPosition: 'left',
            }}
          >
            <div className="icon_arrow">
              <i
                class="bx bx-left-arrow-circle"
                onClick={() => setShowpop(false)}
              ></i>
            </div>
            <div className="center_box">
              <div className="pl-5 pr-5">
                <h1 className="about_text text-light">Become part of us</h1>
                <p className="text-light">
                  Are you looking to create a positive impact in South Sudan?
                  Join us and be one of the peace makers.
                </p>
                <div>
                  <Link to="/engagewithus" className="btn btn-primary">
                    Available Opportunities
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="new_sec bg_light">
            <div className="container  ">
              <div className="row">
                <div className="col-lg-8 col-md-10 col-12 mx-auto">
                  <p className="text-center text-custom fn_20 pt-5 pb-5">
                    We are a women lead organization working for peace and women
                    empowerment in the country. We value diversity, team work
                    and we ensure your talent is further amplified. With us it's
                    not about a career but journey to becoming a whole new
                    person with wider skill set, knowledge and leadership.
                    Become part of us!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
                <div className="card">
                  <img
                    src="./image/teamwork.png"
                    alt=""
                    className="team_work_img "
                  />
                  <div className="p-3 text-center">
                    <h4>TEAM WORK</h4>
                    <p>
                      We believe in the power of team work. When different hands
                      come together, greater things are accomplished.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
                <div className="card">
                  <img
                    src="./image/leadership.png"
                    alt=""
                    className="team_work_img"
                  />
                  <div className="p-3 text-center">
                    <h4>ENHANCEMENT</h4>
                    <p>
                      We learn from each other and empowerment of your skill set
                      if of great importance to us.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mx-auto mb-2">
                <div className="card">
                  <img
                    src="./image/incentive.png"
                    alt=""
                    className="team_work_img"
                  />
                  <div className="p-3 text-center">
                    <h4>INCENTIVES</h4>
                    <p>
                      We offer a peaceful and vibrant work environment, and a
                      place to earn while impacting others.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-6 col-12  mb-2">
                <Link to="/about" className="card_link">
                  <div className="card">
                    <img
                      src="./image/inner/career/about us.webp"
                      alt=""
                      className="annul_img"
                    />
                    <div className="upp_card_text">
                      <h4>ABOUT US</h4>
                      <Link to="/about">
                        <i
                          class="bx bx-right-arrow-circle"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6 col-md-6  col-12  mb-2">
                <Link to="/programs" className="card_link">
                  <div className="card">
                    <img
                      src="./image/inner/career/our programs.webp"
                      alt=""
                      className="annul_img"
                    />
                    <div className="upp_card_text">
                      <h4>OUR PROGRAMS</h4>
                      <Link to="/programs">
                        <i
                          class="bx bx-right-arrow-circle"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default EngageComp;
