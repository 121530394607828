import React, { useState } from "react";
import DownloadReportModal from "./modal";
import { DownloadReportForm } from "./DownloadReportForm";
import { getFileUrl, urlFor } from "../helper";

const AnnualReport = ({ report }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const imageUrl = urlFor(report.mainImage.asset?._ref).url();
  const { url, extension } = getFileUrl(report.pdfFile.asset._ref);
  return (
    <>
<div className="card">
          <img src={imageUrl} alt="" className="annul_img img-fluid" />
          <div className="upp_card_text">
            <h4>{report?.title}</h4>
            <button className="btn btn-ghost" onClick={openModal}>Learn More <i className="bx bx-right-arrow-circle" aria-hidden="true"></i></button>
          </div>
        </div>
      <DownloadReportModal
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
      >
        <DownloadReportForm
          reportDescription={report?.title}
          reportUrl={`${url}?dl=${report.title}-SSWCP.${extension}`}
        />
      </DownloadReportModal>
    </>
  );
};

export const ListAnnualReports = ({ reports }) => {
  return (
    <>
      <div className="card-columns">
        {reports &&
          reports.map((report, index) => (
            <AnnualReport report={{ ...report }} key={index} />
          ))}
      </div>
    </>
  );
};
