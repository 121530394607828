import React, { useState } from "react";
import "./Subscribe.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const errorToast = (message) => toast.error(message);
  const successToast = (message) => toast.success(message);

  const url = `https://gzihphpji1.execute-api.us-east-1.amazonaws.com/prod`;
  const handleSubscription = async () => {

    const body = JSON.stringify({
      email_address: email,
      status: "subscribed",
    });
    try {
      const result = await axios.post(url, {
        body
      });
      console.log(result);
      setEmail("");
      setError(error);
      setIsLoading(false);
      successToast("Subscribed!");
    } catch (error) {
      console.log(error);
      setError(error);
      setIsLoading(false);
      errorToast("Something went wrong");
    }
  };
  return (
    <>
      <div className="calltoaction">
        <div className="p-2">
          <h3 className="text-light text-center ">
            SUBSCRIBE AND STAY CONNECTED WITH US
          </h3>
          <p className="text-center text-light  fn_14">
            Get updates from us and be inspired about the work we do.
          </p>
        </div>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Enter Email Address"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="input-group-append">
            <button
              type="button"
              className="input-group-text btn"
              onClick={handleSubscription}
            >
              {isLoading ? "Submitting" : "Subscribe Now "}
            </button>
          </div>
        </div>
      </div>
      <p>{error ? "Something went wrong" : null}</p>
      <Toaster position="bottom-center" />
    </>
  );
};

export default Subscribe;
