import './normal.css';
import Subscribe from '../../components/calltoaction/Subscribe';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

const errorToast = (message) => toast.error(message);
const successToast = (message) => toast.success(message);

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_138ftjc','template_33jfidp', form.current, 'JbC6yETtMNI4lRCiL')
      .then(
        (result) => {
          console.log(result.text);
          successToast('Contact form submitted!');
        },
        (error) => {
          console.log(error.text);
          errorToast('Something went wrong, try again please!');
        }
      );
  };
  return (
    <>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-lg-11 col-md-11 col-12 mx-auto">
            <div
              className="contact"
              style={{
                background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/contact/DSC07444.webp')`,
                backgroundSize: 'cover',
                backgroundPosition: 'left',
              }}
            >
              <div className="contact_inn">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5 col-md-10 col-10 mx-auto"></div>
                    <div className="col-lg-7 col-md-10 col-10 mx-auto">
                      <h1 className="about_text text-light">Contact us</h1>
                      <div className="card p-3" style={{ opacity: '85%' }}>
                        <form ref={form} onSubmit={sendEmail}>
                          <div class="form-group">
                            <label for="">Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Name"
                              name="name"
                              required
                            />
                          </div>
                          <div class="form-group">
                            <label for="">Email:</label>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Enter Email"
                              name="email"
                              required
                            />
                          </div>

                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for="">Gender:</label>
                              <input
                                type="text"
                                class="form-control"
                                name="gender"
                                placeholder="Enter gender"
                                required
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label for="">Occupation:</label>
                              <input
                                type="text"
                                class="form-control"
                                name="occupation"
                                placeholder="Enter your occupation"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for="">How did you know about us?</label>
                              <input
                                type="text"
                                class="form-control"
                                name="knowabout?"
                                placeholder="Enter how you knew about us?"
                                required
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label for="">Organization:</label>
                              <input
                                type="text"
                                class="form-control"
                                name="Organization"
                                placeholder="Enter Organization"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="">Number:</label>
                            <input
                              type="string"
                              class="form-control"
                              placeholder="Enter phone number"
                              name="number"
                              required
                            />
                          </div>
                          <div class="form-group">
                            <label for="">Message:</label>
                            <textarea
                              class="form-control"
                              rows="3"
                              name="message"
                              placeholder="Enter your message here"
                            ></textarea>
                          </div>
                          <div className="text-right">
                            <button className="btn btn-primary">
                              Send Message
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" />

      <div className="mt-4">
        <Subscribe />
      </div>
    </>
  );
};

export default ContactUs;
