import './Engage.css';
import EngageComp from '../../components/engage/EngageComp';
import ModalD from '../../components/donateModal/donateModal';
const Engage = () => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);
  return (
    <>
      <ModalD />
      <EngageComp />
    </>
  );
};

export default Engage;
