import { Link } from "react-router-dom";
import { urlFor } from "../../helper";

const PostOverview = ({ post }) => {
  const imageUrl = urlFor(post.mainImage).url();
  return (
    <div className="card">
      <img src={imageUrl} alt={post?.title} className="sm_blog_img" />
      <div className="p-2">
        <h4 style={{ textOverflow: "ellipsis" }}>{post?.title}</h4>
        <div>
          <div style={{ height: "100px", overflowY: "hidden" }}>
            <p>{post.description}</p>
          </div>

          <Link to={`/blog/${post?.slug?.current}`}>
            Read More
            <i
              class="ml-2 bx bx-right-arrow-circle text-custom"
              aria-hidden="true"
            ></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

const LatestBlogPostsList = ({ posts }) => {
  if (posts.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">No Blog Posts</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="card-deck">
      {posts &&
        posts.map((post) => <PostOverview post={{ ...post }} key={post._id} />)}
    </div>
  );
};

export default LatestBlogPostsList;
