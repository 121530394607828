import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { companydata, individuals } from "../../data/fdata";
import { MembersTable } from "../../components/about/Members";
import { IndividualsTable } from "../../components/about/IndividualsTable";

const Members = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const his = useHistory();
  return (
    <>
      <div className="pop_up_box">
        <div
          className="pop_box_hero"
          style={{
            background: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/our+members/our+members.webp')`,
            backgroundSize: 'cover',
            backgroundPosition: 'left',
          }}
        >
          <div className="icon_arrow">
            <img
              src="./image/back.png"
              onClick={() => {
                his.goBack();
              }}
              className="cur"
              width="50px"
              alt=""
            />
          </div>
          <div className="center_box">
            <div className="container">
              <div className="pl-5 pr-5">
                <h1 className="about_text text-light" style={{marginTop:"-13%", fontWeight:"bold"}}>Our members</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div
            className="col-lg-6 col-md-10 col-12"
            style={{ marginLeft: "-15px" }}
          >
            <h3 className="bg-dark p-2 text-light">
              List of our members
            </h3>
          </div>
        </div>

        <div className="new_sec">
          <div className="container">
            {/*<h3>Organizational/Company member list</h3>*/}
            <div className="row mt-5">
              <div className="col-12">
              <MembersTable companydata={companydata} />
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <h3>Individual member list</h3>
            <div className="row mt-5">
              <div className="col-12">
                <IndividualsTable individuals={individuals} />
              </div>
            </div>
          </div>
        </div>
        

        <Link to="/contact">
          <div
            className="image_div1"
            style={{
              background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/inner/our+members/Join+our+cause+and+become+part+of+impact+makers.webp')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="container pt-5">
              <div className="row">
                <div className="col-lg-6  col-md-8 col-12 mx-auto d-flex justify-content-center  flex-column">
                  <h1 className=" mb-2 text-light">
                    Join Our Cause and become part of impact makers
                  </h1>

                  <div>
                    <Link to="/contact" className="btn btn-primary">
                      Apply to Join
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-12 mx-auto"></div>
              </div>
            </div>
          </div>
        </Link>

        <Footer />
      </div>
    </>
  );
};

export default Members;
