import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProgCompo = () => {
  const [showone, setShowOne] = useState(false);
  const [showtwo, setShowtwo] = useState(false);
  const [showthree, setShowthree] = useState(false);
  return (
    <>
      <div className="progCompotop">
        <div className="container">
          <h1 className="">
            Our <br/> Programs
          </h1>
        </div>
      </div>
      <div className="new_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 mb-2 order-lg-1 order-md-1 order-2">
              <img
                src="https://s3.amazonaws.com/sswcp.org/image/outer/our+program/Monitoring+the+Implementation+of+the+Peace+agreement+R-ARCSS+-2018.webp"
                alt=""
                className="prog_img"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-12 mb-2 order-lg-2 order-md-2 order-1" style={{textAlign:"justify"}}>
              <h3 className="text-custom" style={{fontWeight:"bold"}}>
                Monitoring the Implementation of the Peace agreement R-ARCSS
                -2018
              </h3>
              <p> 
                The South Sudan Women’s Coalition for Peace is dedicated to
                ensuring the effective and inclusive implementation of the peace
                agreement signed in 2018. We firmly believe that sustainable
                peace can only be achieved by addressing the root causes of
                conflict and by actively involving women in the peacebuilding
                process.{' '}
                {!showone && (
                  <b
                    onClick={() => setShowOne(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    ...Read More
                  </b>
                )}
              </p>
            </div>
          </div>
          {showone && (
            <div className="row">
              <p style={{textAlign:"justify", padding: "0.5rem" }}>
                We closely monitor the implementation of the peace agreement,
                tracking progress and identifying challenges and gaps. Our
                dedicated team collects data, conducts field visits, and engages
                with stakeholders to gather information on the status of various
                peacebuilding initiatives. Through regular reports, we provide
                evidence-based analysis to inform policymakers, stakeholders,
                and the public about the progress and shortcomings in the
                implementation process.
              </p>

              <p style={{textAlign:"justify", padding: "0.5rem"}}>
                We advocate for the meaningful inclusion of women in all aspects
                of peacebuilding and ensure that gender perspectives are
                integrated into the implementation of the peace agreement. We
                work with government institutions, international organizations,
                and civil society groups to promote gender mainstreaming in
                policies, programs, and decision-making processes. By addressing
                the specific needs and experiences of women and girls, we
                contribute to a more inclusive and sustainable peace process.
              </p>
              <p style={{textAlign:"justify", padding: "0.5rem"}}>
                We actively engage with communities affected by conflict,
                conducting dialogues, consultations, and awareness-raising
                campaigns. By involving local communities, particularly women,
                in the monitoring process, we ensure their voices are heard and
                their experiences are taken into account. We provide platforms
                for community members to share their concerns, aspirations, and
                recommendations, facilitating bottom-up feedback that
                strengthens the implementation of the peace agreement.
              </p>
              <p style={{textAlign:"justify", padding: "0.5rem"}}>
                Our organization advocates for the effective implementation of
                the peace agreement at various levels, including national,
                regional, and international forums. We collaborate with other
                civil society organizations, governmental bodies, and regional
                organizations to amplify women's voice.{' '}
                <b
                  onClick={() => setShowOne(false)}
                  style={{ cursor: 'pointer' }}
                >
                  Read Less
                </b>
              </p>
            </div>
          )}

          <div className="row mt-5" id="capacity">
            <div className="col-lg-6 col-md-6 col-12 mb-2" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <h3 className="text-custom" style={{fontWeight:"bold"}}>Capacity Building</h3>
              <p style={{textAlign:"justify", padding: "0.5rem"}}>
                The South Sudan Women's Coalition for Peace is dedicated to
                empowering women in South Sudan through comprehensive capacity
                building initiatives. Our organization understands that
                knowledge and skills are essential for women to actively
                participate in peacebuilding and conflict resolution efforts.
                Through our capacity building programs, we provide training,
                workshops, and resources that equip women with the necessary
                tools to advocate for their rights, engage in meaningful
                dialogue, and promote sustainable peace within their
                communities. By strengthening the capacities of women, we aim to
                create a more inclusive and resilient society in South Sudan.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mb-2" style={{display: "grid", placeItems: "center"}}>
              <img
                src="https://s3.amazonaws.com/sswcp.org/image/outer/our+program/Capacity+Building.webp"
                alt=""
                className="prog_img square"
                style={{boxShadow:"rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="new_sec bg-dark" id="advocacy" style={{minHeight: "20vh"}}>
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-12 text-center col-md-12 col-12 text-light">
              <h1 style={{fontWeight:"bold"}}>Advocacy and Lobby</h1>
              <p className="mt-4"  style={{textAlign:"justify", padding: "0.5rem"}}>
                At the South Sudan Women's Coalition for Peace, we strongly
                believe in the power of advocacy to bring about positive change.
                Our organization is actively engaged in lobbying for policies
                and actions that prioritize gender equality, women's rights, and
                peacebuilding. Through strategic partnerships and
                collaborations, we amplify the voices of women across South
                Sudan, advocating for their inclusion in decision-making
                processes at all levels. By engaging with key stakeholders,
                policymakers, and the public, we strive to influence policies,
                raise awareness, and address the unique challenges faced by
                women in conflict-affected areas. Our lobby and advocacy efforts
                are centered on promoting a more equitable and peaceful society
                in South Sudan.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="new_sec" id="policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-12 mx-auto text-center">
              <h1 style={{fontWeight:"bold"}}>Policy and Legislative Review</h1>
              <p  style={{textAlign:"justify", padding: "0.5rem"}}>
                The South Sudan Women's Coalition for Peace recognizes the
                critical role of policy and legislation in advancing gender
                equality and peacebuilding. The organization actively engages in
                policy research, analysis, and advocacy{' '}
                {!showthree && (
                  <b
                    onClick={() => setShowthree(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    ...Read More
                  </b>
                )}
                {showthree && (
                  <span>
                    to influence the development and implementation of laws that
                    protect and promote women's rights. We work closely with
                    policymakers and relevant institutions to ensure the
                    integration of a gender perspective in all peace and
                    security frameworks. Through our expertise and
                    evidence-based recommendations, we strive to shape policies
                    that address the root causes of gender-based violence,
                    discrimination, and exclusion. By advocating for
                    gender-responsive legislation, we aim to create an enabling
                    environment that supports women's empowerment and
                    sustainable peace in South Sudan.{' '}
                    <b
                      onClick={() => setShowthree(false)}
                      style={{ cursor: 'pointer' }}
                    >
                      Read Less
                    </b>
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="row mt-4" >
            <div className="col-lg-6 col-md-6 col-12 mb-2 order-lg-1 order-md-1 order-2"style={{marginTop:"4rem"}} >
              <img
                src="https://s3.amazonaws.com/sswcp.org/image/outer/our+program/Constitution+Making+and+Elections.webp"
                alt=""
                className="prog_img"
                style={{borderRadius:"0.5rem"}}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-12 mb-2 order-lg-2 order-md-2 order-1"style={{marginTop:"4rem", backgroundColor:"#FAFBEB", borderRadius:"0.5rem"}} >
              <h3 className="" style={{fontWeight:"bold"}}>Constitution Making and Elections</h3>
              <p  style={{textAlign:"justify", padding: "0.5rem"}}>
                South Sudan Women’s Coalition for Peace actively engages in
                promoting inclusive and gender-responsive constitution-making
                processes towards a peaceful and transparent elections in South
                Sudan. We firmly believe that women's voices and perspectives
                are crucial in shaping the future of our nation. We work tirelessly to advocate for the meaningful participation
                of women in constitution-making and electoral processes.Through
                various initiatives, we raise awareness about the importance of
                gender equality, women's rights, and representation in{' '}
                {!showtwo && (
                  <b
                    onClick={() => setShowtwo(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    ...Read More
                  </b>
                )}
              </p>
            </div>
          </div>
          {showtwo && (
            <div className="row">
              <p style={{textAlign:"justify", padding: "0.5rem"}}>decision-making bodies. We collaborate with government
                institutions, civil society organizations, and community leaders
                to promote gender-responsiveconstitutional reforms and electoral systems.
              </p>
              <p  style={{textAlign:"justify", padding: "0.5rem"}}>
                South Sudan Women’s Coalition for Peace empowers women and girls
                by providing them with the necessary skills and knowledge to
                actively engage in constitution-making and electoral activities.
                We organize workshops, trainings, and mentoring programs that
                focus on leadership development, legal literacy, and political
                participation. By strengthening their capacities, we aim to
                enhance women's confidence and the ability to effectively
                contribute to the democratic processes of South Sudan.
              </p>{' '}
              <p  style={{textAlign:"justify", padding: "0.5rem"}}>
                The organization conducts research on constitutional and
                electoral matters, with a specific focus on gender-related
                issues. We analyze existing laws, policies, and practices to
                identify gaps and recommend gender-responsive reforms. We
                collaborate with policymakers and stakeholders to influence
                legislation and policies that promote women's rights and equal
                participation in the constitution-making and electoral
                processes.
              </p>
              <p  style={{textAlign:"justify", padding: "0.5rem"}}>
                At South Sudan Women’s Coalition for Peace, we actively advocate
                for legal reforms that ensure the protection of women's rights
                and gender equality in the context of constitution-making and
                elections. We work closely with relevant authorities and
                institutions to address discriminatory laws and practices, and
                we propose legislative changes that align with international
                human rights standards and best practices. Our aim is to create
                an enabling environment for women's meaningful participation and
                representation in all stages of constitution-making and
                electoral processes.{' '}
                <b
                  onClick={() => setShowtwo(false)}
                  style={{ cursor: 'pointer' }}
                >
                  Read Less
                </b>
              </p>
            </div>
          )}
          <div className="row mt-5" id="growth">
            <div className="col-lg-6 col-md-6 col-12 mb-2 " style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <h3 className="" style={{fontWeight:"bold"}}>Growth of Coalition</h3>
              <p  style={{textAlign:"justify", padding: "0.5rem"}}>
                The South Sudan Women's Coalition for Peace is committed to
                fostering the growth and expansion of our coalition to achieve
                greater impact. We understand that collaboration and collective
                action are vital for advancing women's rights and building
                sustainable peace in South Sudan. Our organization actively
                seeks to strengthen our network by forming partnerships with
                like-minded individuals, civil society organizations, and
                government entities. By bringing together diverse stakeholders,
                we enhance our ability to advocate for gender-responsive
                policies, implement effective peacebuilding strategies, and
                address the specific needs of women affected by conflict. We
                prioritize inclusivity and collaboration as we work towards the
                common goal of creating a united and empowered coalition for
                peace.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mb-2" style={{display: "grid", placeItems: "center"}}>
              <img
                src="https://s3.amazonaws.com/sswcp.org/image/outer/our+program/Growth+of+Coalition+.webp"
                alt=""
                className="prog_img square"
                style={{borderRadius:"1rem",  aspectRatio: "3/4", height: "75%", boxShadow:"rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"}}
                
              />
            </div>
          </div>
        </div>
      </div>
      <Link to="/donate">
        <div
          className="image_div1"
          style={{
            background: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.3)), url('https://s3.amazonaws.com/sswcp.org/image/outer/our+program/You+can+create+a+smile+in+someone+life.webp')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-8  col-md-10 col-12 mx-auto d-flex justify-content-center  flex-column">
                <h1 className="title_text mb-2 text-light fn_50">
                  You can create a smile in someone's life!
                </h1>
                <p className="text-light fn_24">
                  Your monthly or one time donation provides the opportunity to
                  empowers women in our society
                </p>
                <div>
                  <Link to="/donate" className="btn btn-primary">
                    BECOME A SPONSOR
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-2 col-12 mx-auto"></div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProgCompo;
