import React from 'react';
import './normal.css';
import { Link } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

const SearchPage = () => {
  return (
    <>
      <div className="annual ">
        <div className="container">
          <h3>6 results on search keyword</h3>
          <div className="row mt-3 search">
            <div className="col-lg-10 col-md-12 col-12 mx-auto mb-4">
              <div className="card">
                <div className="search_row">
                  <Link to="/blog">
                    <img
                      src="./image/blog1.png"
                      alt=""
                      className="search_row_img p-2"
                    />
                  </Link>
                  <div className="p-2">
                    <Link to="/blog">
                      <h5 className="mt-2 ">Lorem ipsum dolor sit amet.</h5>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Minima ex blanditiis repellendus quasi, pariatur
                        ea omnis voluptatum atque totam beatae.
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 col-12 mx-auto mb-4">
              <div className="card">
                <div className="search_row">
                  <Link to="/blog">
                    <img
                      src="./image/blog1.png"
                      alt=""
                      className="search_row_img p-2"
                    />
                  </Link>
                  <div className="p-2">
                    <Link to="/blog">
                      <h5 className="mt-2 ">Lorem ipsum dolor sit amet.</h5>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Minima ex blanditiis repellendus quasi, pariatur
                        ea omnis voluptatum atque totam beatae.
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
