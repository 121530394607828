import React from "react";
import { dateFormat, urlFor } from "../../helper";

const BlogPostCard = ({ post }) => {
  const imageUrl = urlFor(post.mainImage).url();
  const authorImageUrl = urlFor(post.author.image.asset._ref).url();
  return (
    <div className="card border-light" style={{ boxShadow: "none", maxWidth: "320px", minHeight: "500px" }}>
 
      <div className="card-body flex flex-col justify-content-between">
      <img src={imageUrl} alt={post.title} className="card-img" />
        <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
          <p className="text-primary " style={{ width: "fit-content"}}>{post.categories[0].title}</p>
          <p className="text-secondary" style={{ width: "fit-content"}}>{dateFormat(post._createdAt, "DD, MMMM, YYYY")}</p>
        </div>
        <h5 className="card-title font-weight-bold" style={{ textOverflow: "ellipsis" }}>
          <a className="text-dark" href={`/blog/${post.slug.current}`}>
            {post.title}
          </a>
        </h5>
        <div  style={{ height: "100px", "overflowY": "hidden" }}>
        <p className="card-text">{post.description}</p>
        </div>
        <div className="d-flex align-items-center mt-2">
          <div className="mr-2" style={{ width: "45px", height: "45px" }}><img src={authorImageUrl} className="img-fluid rounded-circle" alt={post.author.name} />
          </div>
          <p>{post.author.name}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
